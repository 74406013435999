/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';

import ControlMenu from './ControlMenu';
import css from './AddImages.module.css';

const ThumbnailWrapper = props => {
  const {
    className,
    image,
    listing,
    savedImageAltText,
    removeImageText,
    disableCover,
    disablePoster,
    setCoverInProgress,
    setCoverError,
    setPosterInProgress,
    setPosterError,
    onRemoveImage,
    onSetCover,
    onSetPoster,
  } = props;

  const imageId = image.imageId || image.id;

  const { publicData } = listing.attributes;
  const coverPhoto =
    typeof imageId !== 'undefined' && publicData.coverId && publicData.coverId === imageId.uuid;
  const posterPhoto =
    typeof imageId !== 'undefined' && publicData.posterId && publicData.posterId === imageId.uuid;

  const handleRemoveClick = e => {
    e.stopPropagation();

    if (window.confirm(removeImageText)) {
      onRemoveImage(image.id);
    }
  };
  const handleCoverClick = e => {
    e.stopPropagation();
    const photoId = coverPhoto ? null : imageId;
    onSetCover(listing.id, photoId);
  };
  const handlePosterClick = e => {
    e.stopPropagation();
    const photoId = posterPhoto ? null : imageId;
    onSetPoster(listing.id, photoId);
  };

  const coverLabel = coverPhoto ? (
    <span className={css.cover}>
      <FormattedMessage id="AddImages.coverPhoto" />
    </span>
  ) : null;

  const posterLabel = posterPhoto ? (
    <span className={css.poster}>
      <FormattedMessage id="AddImages.posterPhoto" />
    </span>
  ) : null;

  const badges =
    coverLabel || posterLabel ? (
      <div className={css.badges}>
        {posterLabel}
        {coverLabel}
      </div>
    ) : null;

  if (image.file) {
    // Add control menu only when the image has been uploaded and can be removed
    const controlMenu = image.imageId ? (
      <ControlMenu
        onRemoveImage={handleRemoveClick}
        onSetCover={handleCoverClick}
        onSetPoster={handlePosterClick}
        setCoverInProgress={setCoverInProgress}
        setCoverError={setCoverError}
        setPosterInProgress={setPosterInProgress}
        setPosterError={setPosterError}
        coverPhoto={coverPhoto}
        posterPhoto={posterPhoto}
        disableCover={disableCover}
      />
    ) : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner className={css.spinnerIcon} />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={className}
        rootClassName={css.thumbnail}
        file={image.file}
      >
        {controlMenu}
        {badges}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className);
    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={{
                ...image,
                id: imageId,
              }}
              alt={savedImageAltText}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          {badges}
          <ControlMenu
            setCoverInProgress={setCoverInProgress}
            setCoverError={setCoverError}
            setPosterInProgress={setPosterInProgress}
            setPosterError={setPosterError}
            coverPhoto={coverPhoto}
            posterPhoto={posterPhoto}
            disableCover={disableCover}
            disablePoster={disablePoster}
            onRemoveImage={handleRemoveClick}
            onSetCover={handleCoverClick}
            onSetPoster={handlePosterClick}
          />
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = {
  className: null,
  listing: null,
  onSetCover: null,
  setCoverInProgress: false,
  setCoverError: null,
};

const { array, func, node, string, object, bool } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  listing: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
  onSetCover: func.isRequired,
  setCoverInProgress: bool.isRequired,
  setCoverError: propTypes.error,
};

const AddImages = props => {
  const { children, rootClassName, className, thumbnailClassName, images, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {images.map(image => {
        const imageId = image.imageId || image.id;
        const imageUUID = imageId.uuid ? imageId.uuid : imageId;
        return (
          <ThumbnailWrapper
            className={thumbnailClassName}
            key={imageUUID}
            id={imageUUID}
            image={image}
            {...rest}
          />
        );
      })}
      {children}
    </div>
  );
};

AddImages.defaultProps = {
  rootClassName: null,
  className: null,
  thumbnailClassName: null,
  images: [],
};

AddImages.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
  thumbnailClassName: string,
  images: array,
};

export default AddImages;
