import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingContentForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '..';

import css from './EditListingContentPanel.module.css';

const EditListingContentPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    location,
    listing,
    images,
    videos,
    onImageUpload,
    onUpdateImageOrder,
    onVideosUpload,
    onChange,
    onSubmit,
    onRemoveImage,
    onRemoveVideo,
    onSetCoverPhoto,
    onSetPosterPhoto,
    onUpdateImages,
    onRedirectToPreviousTab,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    setCoverPhotoInProgress,
    setCoverPhotoError,
    setPosterPhotoInProgress,
    setPosterPhotoError,
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);

  const currentListing = ensureOwnListing(listing);
  const { title } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingContentPanel.title"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing}>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingContentPanel.createListingTitle" />
  );
  const videosPanelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingContentPanel.videosTitle"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing}>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingContentPanel.createListingVideosTitle" />
  );

  return (
    <div className={classes}>
      <EditListingContentForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        location={location}
        fetchErrors={errors}
        listing={listing}
        images={images}
        videos={videos}
        onImageUpload={onImageUpload}
        onUpdateImages={onUpdateImages}
        onSubmit={values => {
          const { addImages, addVideos, videos, ...updateValues } = values;

          // Pass the update values which currently contains only
          // images, and pass the uploaded videos to the publicData
          const submitValues = {
            ...updateValues,
            publicData: {
              videos: videos.filter(video => !video.invalid),
            },
          };

          onSubmit(submitValues);
        }}
        onChange={onChange}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
        onRemoveVideo={onRemoveVideo}
        onSetCoverPhoto={onSetCoverPhoto}
        onSetPosterPhoto={onSetPosterPhoto}
        setCoverPhotoInProgress={setCoverPhotoInProgress}
        setCoverPhotoError={setCoverPhotoError}
        setPosterPhotoInProgress={setPosterPhotoInProgress}
        setPosterPhotoError={setPosterPhotoError}
        onVideosUpload={onVideosUpload}
        onRedirectToPreviousTab={onRedirectToPreviousTab}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        panelTitle={panelTitle}
        videosPanelTitle={videosPanelTitle}
      />
    </div>
  );
};

EditListingContentPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
  setCoverPhotoInProgress: false,
  setCoverPhotoError: null,
  setPosterPhotoInProgress: false,
  setPosterPhotoError: null,
};

EditListingContentPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  onSetCoverPhoto: func.isRequired,
  onSetPosterPhoto: func.isRequired,
  onUpdateImages: func.isRequired,
  setCoverPhotoInProgress: bool.isRequired,
  setCoverPhotoError: propTypes.error,
  setPosterPhotoInProgress: bool.isRequired,
  setPosterPhotoError: propTypes.error,
};

export default EditListingContentPanel;
