import React, { useState } from 'react';
import { array, string, bool, func } from 'prop-types';
import { propTypes } from '../../../util/types';
import { notification } from '../../../util/notification';
import { FormattedMessage } from '../../../util/reactIntl';
import { FieldArray } from 'react-final-form-arrays';
import {
  HorizontalUserCard,
  HorizontalNonExistingUserCard,
  SecondaryButton,
  InlineTextButton,
  IconSpinner,
} from '../../../components';
import classNames from 'classnames';

import css from './EditExperienceTeamField.module.css';

const SHOW_LENGTH = 6;
const RESEND_INVITE_DISABLED_DELAY = 5000;

const User = props => {
  const {
    user,
    currentUser,
    listing,
    teamFromPublicData,
    resendInviteInProgress,
    resendInviteError,
    revokeInviteInProgress,
    revokeInviteError,
    removeInviteInProgress,
    removeInviteError,
    resendDisabled,
    resendId,
    revokeId,
    removeId,
    handleResendInvite,
    handleRevokeInvite,
    handleRemoveInvite,
  } = props;

  const isCurrentUser = user.id.uuid === currentUser.id.uuid;

  const currentInvite = teamFromPublicData.find(teamMember => teamMember.id === user.id.uuid);
  const isPendingInvite = currentInvite?.pending;
  const formValues = { credit: currentInvite?.credit, year: currentInvite?.year };

  const resendInProgress = resendInviteInProgress && resendId === user.id.uuid;
  const resendError = resendInviteError && resendId === user.id.uuid;
  const resendButtonDisabled = (resendInProgress || resendDisabled) && resendId === user.id.uuid;

  const revokeInProgress = revokeId === user.id.uuid && revokeInviteInProgress;
  const removeInProgress = removeId === user.id.uuid && removeInviteInProgress;

  const revokeError = revokeId === user.id.uuid && !revokeInviteInProgress && revokeInviteError;
  const removeError = removeId === user.id.uuid && !removeInviteInProgress && removeInviteError;

  const renderPendingInviteContent = isCurrentUser ? null : revokeError ? (
    <p className={css.revokeError}>
      <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.revokeError" />
    </p>
  ) : (
    <div className={css.userButtons}>
      <SecondaryButton
        type="button"
        className={classNames(css.userButton, css.resendButton)}
        inProgress={resendInProgress}
        disabled={resendButtonDisabled}
        onClick={() =>
          handleResendInvite(user.id.uuid, listing.id.uuid, user.attributes.email, formValues)
        }
      >
        <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.resendButtonText" />
      </SecondaryButton>
      <SecondaryButton
        type="button"
        className={css.userButton}
        onClick={() => handleRevokeInvite(listing.id.uuid, user.id.uuid)}
        inProgress={revokeInProgress}
        disabled={revokeInProgress}
      >
        <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.revokeButtonText" />
      </SecondaryButton>
    </div>
  );

  const renderInviteContent = isCurrentUser ? null : removeError ? (
    <p className={css.removeError}>
      <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.removeError" />
    </p>
  ) : resendError ? (
    <p className={css.removeError}>
      <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.resendError" />
    </p>
  ) : (
    <SecondaryButton
      type="button"
      className={css.userButton}
      onClick={() => handleRemoveInvite(listing.id.uuid, user.id.uuid)}
      inProgress={removeInProgress}
      disabled={removeInProgress}
    >
      <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.removeButtonText" />
    </SecondaryButton>
  );

  const renderContent = isPendingInvite ? renderPendingInviteContent : renderInviteContent;

  return user.type ? (
    <HorizontalUserCard
      user={user}
      textContent={formValues?.credit}
      isPendingInvite={isPendingInvite}
    >
      {renderContent}
    </HorizontalUserCard>
  ) : (
    <HorizontalNonExistingUserCard
      user={user}
      textContent={formValues?.credit}
      isPendingInvite={isPendingInvite}
    >
      {renderContent}
    </HorizontalNonExistingUserCard>
  );
};

const EditExperienceTeamField = props => {
  const [showLength, setShowLength] = useState(SHOW_LENGTH);
  const [resendDisabled, setResendDisabled] = useState(false);
  const {
    rootClassName,
    className,
    intl,
    currentUser,
    listing,
    teamFromValues,
    showInvitesInProgress,
    showInvitesError,
    onResendInvite,
    onRevokeInvite,
    onRemoveInvite,
    setInviteModalOpen,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const teamFromPublicData = listing?.attributes?.publicData?.team || [];
  const hasResults = teamFromValues?.length > 0;

  const inviteButton = (
    <SecondaryButton
      className={css.inviteButton}
      type="button"
      onClick={() => setInviteModalOpen(true)}
    >
      <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.inviteButtonText" />
    </SecondaryButton>
  );

  if (showInvitesInProgress) {
    return (
      <div className={classes}>
        <div className={css.users}>
          <IconSpinner className={css.loadingIcon} />
        </div>
      </div>
    );
  } else if (showInvitesError) {
    return (
      <div className={classes}>
        {inviteButton}
        <div className={css.users}>
          <p className={css.errorText}>
            <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.error" />
          </p>
        </div>
      </div>
    );
  } else if (!showInvitesInProgress && !hasResults) {
    return (
      <div className={classes}>
        {inviteButton}
        <div className={css.users}>
          <p className={css.noResultsText}>
            <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.noResults" />
          </p>
        </div>
      </div>
    );
  }

  const handleResendInvite = (invitedUserId, listingId, email, formValues) => {
    setResendDisabled(true);

    onResendInvite(invitedUserId, listingId, email, formValues).then(() => {
      notification.success('Invite succesfully resent.');

      setTimeout(() => {
        setResendDisabled(false);
      }, [RESEND_INVITE_DISABLED_DELAY]);
    });
  };

  const handleRevokeInvite = (listingId, invitedUserId) => {
    if (
      window.confirm(
        intl.formatMessage({
          id: 'EditExperienceTeamField.confirmRevokeInvite',
        })
      )
    ) {
      onRevokeInvite(listingId, invitedUserId).then(() => {
        notification.success('Invite succesfully revoked.');
      });
    }
  };

  const handleRemoveInvite = (listingId, invitedUserId) => {
    if (
      window.confirm(
        intl.formatMessage({
          id: 'EditExperienceTeamField.confirmRemoveInvite',
        })
      )
    ) {
      onRemoveInvite(listingId, invitedUserId).then(() => {
        notification.success('Invite succesfully removed.');
      });
    }
  };

  const isShowMoreButtonVisible = showLength < teamFromValues.length;

  return (
    <div className={classes}>
      {inviteButton}
      <FieldArray name="team">
        {({ fields }) => (
          <div className={css.users}>
            {fields.map((name, index) => {
              if (index + 1 > showLength) {
                return null;
              }
              return (
                <User
                  key={index}
                  user={fields.value[index]}
                  currentUser={currentUser}
                  listing={listing}
                  teamFromPublicData={teamFromPublicData}
                  resendDisabled={resendDisabled}
                  handleResendInvite={handleResendInvite}
                  handleRevokeInvite={handleRevokeInvite}
                  handleRemoveInvite={handleRemoveInvite}
                  {...rest}
                />
              );
            })}
            {isShowMoreButtonVisible ? (
              <InlineTextButton
                type="button"
                className={css.showMoreButton}
                onClick={() => setShowLength(prev => prev + SHOW_LENGTH)}
              >
                <FormattedMessage id="EditExperienceTeamForm.EditExperienceTeamField.showMore" />
              </InlineTextButton>
            ) : null}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

EditExperienceTeamField.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  listing: null,
  showInvitesInProgress: false,
  showInvitesError: null,
  setInviteModalOpen: null,
};

EditExperienceTeamField.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  listing: propTypes.ownListing,
  teamFromValues: array.isRequired,
  showInvitesInProgress: bool.isRequired,
  showInvitesError: propTypes.error,
  onResendInvite: func.isRequired,
  setInviteModalOpen: func.isRequired,
};

export default EditExperienceTeamField;
