import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { Modal, InlineTextButton } from '../../components';
import classNames from 'classnames';

import css from './LanguageModal.module.css';

const DEFAULT_LANGUAGE_CODE = 'en';

const languages = [
  { code: 'en', language: 'English', country: 'United States' },
  {
    code: 'fr',
    language: 'Français',
    country: 'France',
  },
];

const LanguageSelector = props => {
  const { selectedLanguageId, onSelectLanguage } = props;
  return (
    <div className={css.languages}>
      {languages.map(language => (
        <LanguageOption
          key={language.code}
          language={language}
          isSelected={language.code === selectedLanguageId}
          onSelectLanguage={() => onSelectLanguage(language.code)}
        />
      ))}
    </div>
  );
};

const LanguageOption = props => {
  const { language, isSelected, onSelectLanguage } = props;

  const languageClasses = classNames(css.language, {
    [css.selectedLanguage]: isSelected,
  });

  return (
    <InlineTextButton className={languageClasses} onClick={onSelectLanguage} type="button">
      <div className={css.languageText}>{language.language}</div>
      <div className={css.languageCountry}>{language.country}</div>
    </InlineTextButton>
  );
};

const LanguageModal = props => {
  const { className, rootClassName, id, isOpen, onCloseModal, onManageDisableScrolling } = props;
  const [selectedLanguageId, onSelectLanguage] = useState(DEFAULT_LANGUAGE_CODE);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="LanguageModal.title" />
      </p>
      <LanguageSelector
        selectedLanguageId={selectedLanguageId}
        onSelectLanguage={onSelectLanguage}
      />
    </Modal>
  );
};

const { string } = PropTypes;

LanguageModal.defaultProps = {
  className: null,
  rootClassName: null,
};

LanguageModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(LanguageModal);
