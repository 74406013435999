import React from 'react';
import { arrayOf, oneOfType, object, bool, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  uniqueListingTitle,
  validURL,
} from '../../util/validators';
import {
  Form,
  EditWizardButton,
  FieldTextInput,
  FieldSelectInput,
  FieldMultiSelectInput,
  FieldCheckbox,
  LocationAutocompleteInputField,
  PreviewListingButton,
} from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import getCountryCodes from '../../translations/countryCodes';
import moment from 'moment';

import FieldDatesInput from './FieldDatesInput';
import css from './EditListingBasicInfoForm.module.css';

const TITLE_MAX_LENGTH = 60;
const MENU_PLACEMENT = 'top';
const CREATE_ORGANIZATION_ID = 'ccba651e-3447-42b5-b144-0f846ab156a7';

// options, configs for listing fields
const yearsOptions = Array.from({ length: new Date().getFullYear() - 1979 }, (_, i) => ({
  key: (new Date().getFullYear() - i).toString(),
  label: (new Date().getFullYear() - i).toString(),
}));
const supportedCountries = getCountryCodes('en').map(c => ({
  key: c.code,
  label: c.name,
}));

const identity = v => v;

// Redirect to OrganizationSettingsPage.
const onRedirectToOrganizationSettingsPage = (location, history, listingTitle) => {
  const state = {
    from: `${location.pathname}${location.search}${location.hash}`,
    showName: listingTitle,
  };

  const routes = routeConfiguration();
  history.push(createResourceLocatorString('OrganizationSettingsPage', routes, {}, {}), state);
};

const projectDatesTBC = {
  key: 'tbc',
  label: 'Project launch date will be confirmed (TBC)',
};

const EditListingBasicInfoFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        listing,
        history,
        location,
        panelTitle,
        showListings,
        categoryOptions,
        genreOptions,
        statusOptions,
        lengthOptions,
        ageOptions,
        organizationProfiles,
        organizationIdFromPublicData,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingBasicInfoForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingBasicInfoForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const uniqueListingTitleMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.uniqueListingTitle',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.descriptionRequired',
      });

      const categoryMessage = intl.formatMessage({ id: 'EditListingBasicInfoForm.category' });
      const categoryRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.categoryRequired',
      });

      const genreMessage = intl.formatMessage({ id: 'EditListingBasicInfoForm.genre' });
      const genreRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.genreRequired',
      });

      const statusMessage = intl.formatMessage({ id: 'EditListingBasicInfoForm.status' });
      const statusRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.statusRequired',
      });

      const locationMessage = intl.formatMessage({ id: 'EditListingBasicInfoForm.location' });
      const locationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.locationPlaceholder',
      });
      const locationRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.locationRequired',
      });
      const locationNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.locationNotRecognized',
      });

      const productionMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.production',
      });
      const productionCreateOrganizationMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.productionCreateOrganization',
      });
      const productionRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfo.productionRequired',
      });
      const productionRequired = required(productionRequiredMessage);

      const lengthMessage = intl.formatMessage({ id: 'EditListingBasicInfoForm.length' });
      const lengthRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.lengthRequired',
      });

      const launchMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.launch',
      });
      const launchPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.launchPlaceholder',
      });
      const launchRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.launchRequired',
      });

      const originMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.origin',
      });
      const originPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.originPlaceholder',
      });
      const originRequiredMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.originRequired',
      });

      const websiteMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.website',
      });
      const websitePlaceholderMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.websitePlaceholder',
      });
      const websiteInvalidMessage = intl.formatMessage({
        id: 'EditListingBasicInfoForm.websiteInvalid',
      });

      const ageMessage = intl.formatMessage({ id: 'EditListingBasicInfoForm.age' });

      const datesLabel = intl.formatMessage({
        id: 'EditListingBasicInfoForm.dates',
      });
      const startDatePlaceholder = intl.formatMessage({
        id: 'EditListingBasicInfoForm.startDatePlaceholder',
      });
      const endDatePlaceholder = intl.formatMessage({
        id: 'EditListingBasicInfoForm.endDatePlaceholder',
      });

      const selectInputPlaceholder = intl.formatMessage({
        id: 'EditListingBasicInfoForm.selectPlaceholder',
      });

      const startDateInputProps = {
        placeholderText: startDatePlaceholder,
      };
      const endDateInputProps = {
        placeholderText: endDatePlaceholder,
      };

      const dateInputProps = {
        startDateInputProps,
        endDateInputProps,
      };

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBasicInfoForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBasicInfoForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBasicInfoForm.showListingFailed" />
        </p>
      ) : null;

      const organizationOptions = [
        ...organizationProfiles.map(o => ({
          key: o.id,
          label: o.name,
        })),
        {
          key: CREATE_ORGANIZATION_ID,
          label: productionCreateOrganizationMessage,
        },
      ];
      const timeZone = moment.tz.guess();
      const datesDisabled = values.projectDates && values.projectDates?.[0] === projectDatesTBC.key;
      const listingTitle = listing?.attributes?.title || values.title;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        (!datesDisabled && !(values.startDate && values.endDate));

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.content}>
            <div className={css.contentWrapper}>
              <h1 className={css.title}>{panelTitle}</h1>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              <FieldTextInput
                id="title"
                name="title"
                type="text"
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(
                  required(titleRequiredMessage),
                  maxLength60Message,
                  uniqueListingTitle(uniqueListingTitleMessage, showListings)
                )}
              />

              <FieldSelectInput
                id="category"
                name="category"
                className={css.field}
                options={categoryOptions}
                label={categoryMessage}
                placeholder={selectInputPlaceholder}
                validate={required(categoryRequiredMessage)}
              />

              <FieldSelectInput
                id="genre"
                name="genre"
                className={css.field}
                options={genreOptions}
                label={genreMessage}
                placeholder={selectInputPlaceholder}
                validate={required(genreRequiredMessage)}
              />

              <div className={css.bookingDates}>
                <label>{datesLabel}</label>
                <FieldDatesInput
                  {...dateInputProps}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  disabled={datesDisabled}
                />
              </div>
              <div className={css.projectDatesField}>
                <FieldCheckbox
                  id="projectDates"
                  name="projectDates"
                  label={projectDatesTBC.label}
                  value={projectDatesTBC.key}
                />
              </div>

              <div className={css.fieldsFlex}>
                <FieldSelectInput
                  id="status"
                  name="status"
                  className={css.field}
                  options={statusOptions}
                  label={statusMessage}
                  placeholder={selectInputPlaceholder}
                  validate={required(statusRequiredMessage)}
                />
                <LocationAutocompleteInputField
                  rootClassName={css.field}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  name="location"
                  label={locationMessage}
                  placeholder={locationPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(locationRequiredMessage),
                    autocompletePlaceSelected(locationNotRecognizedMessage)
                  )}
                />
              </div>

              <FieldTextInput
                id="description"
                name="description"
                className={css.field}
                type="textarea"
                label={descriptionMessage}
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />

              <div className={css.fieldsFlex}>
                <FieldSelectInput
                  id="length"
                  name="length"
                  className={css.field}
                  options={lengthOptions}
                  label={lengthMessage}
                  placeholder={selectInputPlaceholder}
                  validate={required(lengthRequiredMessage)}
                />
                <FieldSelectInput
                  id="age"
                  name="age"
                  className={css.field}
                  options={ageOptions}
                  label={ageMessage}
                  placeholder={selectInputPlaceholder}
                />
              </div>

              <div className={css.fieldsFlex}>
                <FieldSelectInput
                  id="launch"
                  name="launch"
                  className={css.field}
                  options={yearsOptions}
                  label={launchMessage}
                  placeholder={launchPlaceholderMessage}
                  validate={required(launchRequiredMessage)}
                />
                <FieldSelectInput
                  id="countryOfOrigin"
                  name="countryOfOrigin"
                  className={css.field}
                  options={supportedCountries}
                  label={originMessage}
                  placeholder={originPlaceholderMessage}
                  validate={required(originRequiredMessage)}
                />
              </div>

              <FieldTextInput
                className={css.field}
                id="website"
                name="website"
                type="text"
                label={websiteMessage}
                placeholder={websitePlaceholderMessage}
                validate={validURL(websiteInvalidMessage)}
              />

              <FieldMultiSelectInput
                id="organizationId"
                name="organizationId"
                className={css.field}
                label={productionMessage}
                placeholder={selectInputPlaceholder}
                options={organizationOptions}
                menuPlacement={MENU_PLACEMENT}
                onChange={selectedOption => {
                  if (selectedOption === CREATE_ORGANIZATION_ID) {
                    onRedirectToOrganizationSettingsPage(location, history, listingTitle);
                  }
                }}
                disabled={!!organizationIdFromPublicData}
                // Specify that the select field shouldn't accept
                // multiple values selection.
                isMulti={false}
                validate={productionRequired}
              />
              {organizationIdFromPublicData ? (
                <span className={css.fieldNotice}>
                  <FormattedMessage
                    id="EditListingBasicInfoForm.organizationNotice"
                    values={{
                      email: (
                        <span className={css.contactEmail}>
                          {process.env.REACT_APP_MARKETPLACE_CONTACT_EMAIL}
                        </span>
                      ),
                    }}
                  />
                </span>
              ) : null}
            </div>
          </div>

          <div className={css.submitButtonRoot}>
            <PreviewListingButton listing={listing} />
            <EditWizardButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </EditWizardButton>
          </div>
        </Form>
      );
    }}
  />
);

EditListingBasicInfoFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  panelTitle: null,
  organizationIdFromPublicData: null,
};

EditListingBasicInfoFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  panelTitle: oneOfType([object, node]),
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  showListings: arrayOf(propTypes.listing),
  organizationIdFromPublicData: string,
};

export default compose(injectIntl)(EditListingBasicInfoFormComponent);
