import React from 'react';
import { oneOfType, object, bool, func, shape, string, node, array } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  Form,
  EditWizardButton,
  FieldTextInput,
  FieldSelectInput,
  FieldMultiSelectInput,
  PreviewListingButton,
  IconSpinner,
} from '../../components';

import css from './EditExperienceDetailsForm.module.css';

const TITLE_MAX_LENGTH = 60;
const MENU_PLACEMENT = 'top';

const CREATE_SHOW_ID = 'ccba651e-3447-42b5-b144-0f846ab156a7';

// Multi select max options
const AGE_MAX_OPTIONS = 2;
const LANGUAGE_MAX_OPTIONS = 2;

/**
 * Formats the FieldMultiSelect placeholder from the given
 * array of options.
 *
 * @param {array} options
 *
 * @return {string} option label
 */
const multiSelectPlaceholder = options => options[0].label;

// Redirect to NewListingPage (e.g Create Show Page).
const onRedirectToCreateShowPage = history => {
  const routes = routeConfiguration();
  history.push(createResourceLocatorString('NewListingPage', routes));
};

const EditExperienceDetailsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        history,
        panelTitle,
        listing,
        categoryOptions,
        themeOptions,
        ageOptions,
        languageOptions,
        listingShows,
        queryShowsInProgress,
        showIdFromPublicData,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditExperienceDetailsForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditExperienceDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.descriptionRequired',
      });

      const categoryMessage = intl.formatMessage({ id: 'EditExperienceDetailsForm.category' });
      const categoryRequiredMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.categoryRequired',
      });

      const showMessage = intl.formatMessage({ id: 'EditExperienceDetailsForm.show' });
      const showPlaceholderMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.showPlaceholder',
      });

      const themeMessage = intl.formatMessage({ id: 'EditExperienceDetailsForm.theme' });

      const ageMessage = intl.formatMessage({ id: 'EditExperienceDetailsForm.age' });
      const agePlaceholder = multiSelectPlaceholder(ageOptions);

      const languageMessage = intl.formatMessage({ id: 'EditExperienceDetailsForm.language' });
      const languagePlaceholder = multiSelectPlaceholder(languageOptions);

      const selectInputPlaceholder = intl.formatMessage({
        id: 'EditExperienceDetailsForm.selectPlaceholder',
      });

      const createShowMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.createShow',
      });
      const showRequiredMessage = intl.formatMessage({
        id: 'EditExperienceDetailsForm.showRequired',
      });
      const showRequired = required(showRequiredMessage);

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditExperienceDetailsForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditExperienceDetailsForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditExperienceDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const listingShowOptions = listingShows
        .map(l => ({
          key: l.id.uuid,
          label: l.title,
        }))
        .concat({
          key: CREATE_SHOW_ID,
          label: createShowMessage,
        });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.content}>
            <div className={css.contentWrapper}>
              <h1 className={css.title}>{panelTitle}</h1>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}
              <FieldTextInput
                id="title"
                name="title"
                type="text"
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              />

              <div className={css.fieldsFlex}>
                <FieldSelectInput
                  id="category"
                  name="category"
                  className={css.field}
                  options={categoryOptions}
                  label={categoryMessage}
                  placeholder={selectInputPlaceholder}
                  validate={required(categoryRequiredMessage)}
                />
                <FieldSelectInput
                  id="theme"
                  name="theme"
                  className={css.field}
                  options={themeOptions}
                  label={themeMessage}
                  placeholder={selectInputPlaceholder}
                />
              </div>

              {queryShowsInProgress ? (
                <div className={css.loadingField}>
                  <label>{showMessage}</label>
                  <div className={css.loadingFieldPlaceholder}>
                    <IconSpinner className={css.loadingFieldSpinner} />
                  </div>
                </div>
              ) : (
                <>
                  <FieldMultiSelectInput
                    id="show"
                    name="show"
                    className={css.field}
                    label={showMessage}
                    placeholder={showPlaceholderMessage}
                    options={listingShowOptions}
                    onChange={selectedOption => {
                      if (selectedOption === CREATE_SHOW_ID) {
                        onRedirectToCreateShowPage(history);
                      }
                    }}
                    disabled={!!showIdFromPublicData}
                    // Specify that the select field shouldn't accept
                    // multiple values selection.
                    isMulti={false}
                    validate={showRequired}
                  />
                  {showIdFromPublicData ? (
                    <span className={css.fieldNotice}>
                      <FormattedMessage
                        id="EditExperienceDetailsForm.showNotice"
                        values={{
                          email: (
                            <span className={css.contactEmail}>
                              {process.env.REACT_APP_MARKETPLACE_CONTACT_EMAIL}
                            </span>
                          ),
                        }}
                      />
                    </span>
                  ) : null}
                </>
              )}

              <FieldTextInput
                id="description"
                name="description"
                className={css.field}
                type="textarea"
                label={descriptionMessage}
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />

              <div className={css.fieldsFlex}>
                <FieldMultiSelectInput
                  id="age"
                  name="age"
                  className={css.field}
                  label={ageMessage}
                  placeholder={agePlaceholder}
                  options={ageOptions}
                  maxOptions={AGE_MAX_OPTIONS}
                  menuPlacement={MENU_PLACEMENT}
                />
                <FieldMultiSelectInput
                  id="language"
                  name="language"
                  className={css.field}
                  label={languageMessage}
                  placeholder={languagePlaceholder}
                  options={languageOptions}
                  maxOptions={LANGUAGE_MAX_OPTIONS}
                  menuPlacement={MENU_PLACEMENT}
                />
              </div>
            </div>
          </div>

          <div className={css.submitButtonRoot}>
            <PreviewListingButton listing={listing} />
            <EditWizardButton
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </EditWizardButton>
          </div>
        </Form>
      );
    }}
  />
);

EditExperienceDetailsFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  panelTitle: null,
  showIdFromPublicData: null,
};

EditExperienceDetailsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  panelTitle: oneOfType([object, node]),
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  showIdFromPublicData: string,

  // filter configs
  categoryOptions: array.isRequired,
  themeOptions: array.isRequired,
  ageOptions: array.isRequired,
  languageOptions: array.isRequired,
};

export default compose(injectIntl)(EditExperienceDetailsFormComponent);
