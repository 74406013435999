import omit from 'lodash/omit';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { searchListings } from '../SearchPage/SearchPage.duck';
import { uploadVideo } from '../../ducks/googleStorage.duck';
import { showListingInvites } from '../../ducks/invites.duck';
import * as log from '../../util/log';
import config from '../../config';
import { integrationAPI } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';

const { UUID } = sdkTypes;

// Helper object of functions that reduces image ids
// when uploading multiple images at once
const reduceImageIds = {
  requested: images =>
    images.reduce((obj, item) => Object.assign(obj, { [item.id]: { ...item } }), {}),
  uploaded: (images, tempIds) =>
    images.reduce(
      (obj, item, index) =>
        Object.assign(obj, {
          [tempIds[index]]: { ...item, imageId: item.id, id: tempIds[index] },
        }),
      {}
    ),
};

// After listing creation & update, we want to make sure that uploadedImages state is cleaned
const updateUploadedImagesState = (state, payload) => {
  const { images, imageOrder } = state;

  // Images attached to listing entity
  const attachedImages = payload?.data?.relationships?.images?.data || [];
  const attachedImageUUIDStrings = attachedImages.map(img => img.id.uuid);

  // Uploaded images (which are propably not yet attached to listing)
  const unattachedImages = Object.values(state.images);
  const duplicateImageEntities = unattachedImages.filter(unattachedImg =>
    attachedImageUUIDStrings.includes(unattachedImg.imageId?.uuid)
  );
  return duplicateImageEntities.length > 0
    ? {
        images: {},
        imageOrder: [],
      }
    : {
        images,
        imageOrder,
      };
};

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditListingPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditListingPage/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditListingPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditListingPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditListingPage/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditListingPage/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditListingPage/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditListingPage/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditListingPage/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditListingPage/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditListingPage/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditListingPage/SHOW_LISTINGS_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/EditListingPage/UPDATE_IMAGE_ORDER';

export const REMOVE_LISTING_IMAGE = 'app/EditListingPage/REMOVE_LISTING_IMAGE';

export const SET_COVER_PHOTO_REQUEST = 'app/EditListingPage/SET_COVER_PHOTO_REQUEST';
export const SET_COVER_PHOTO_SUCCESS = 'app/EditListingPage/SET_COVER_PHOTO_SUCCESS';
export const SET_COVER_PHOTO_ERROR = 'app/EditListingPage/SET_COVER_PHOTO_ERROR';

export const SET_POSTER_PHOTO_REQUEST = 'app/EditListingPage/SET_POSTER_PHOTO_REQUEST';
export const SET_POSTER_PHOTO_SUCCESS = 'app/EditListingPage/SET_POSTER_PHOTO_SUCCESS';
export const SET_POSTER_PHOTO_ERROR = 'app/EditListingPage/SET_POSTER_PHOTO_ERROR';

export const UPLOAD_VIDEOS_REQUEST = 'app/EditListingPage/UPLOAD_VIDEOS_REQUEST';
export const UPLOAD_VIDEOS_SUCCESS = 'app/EditListingPage/UPLOAD_VIDEOS_SUCCESS';
export const UPLOAD_VIDEOS_ERROR = 'app/EditListingPage/UPLOAD_VIDEOS_ERROR';

export const REMOVE_LISTING_VIDEO = 'app/EditListingPage/REMOVE_LISTING_VIDEO';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  uploadImageErrorIds: [],
  createListingDraftInProgress: false,
  redirectToListing: false,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  removedVideoIds: [],
  fetchExceptionsError: null,
  fetchExceptionsInProgress: false,
  addExceptionError: null,
  addExceptionInProgress: false,
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  setCoverPhotoInProgress: false,
  setCoverPhotoError: null,
  setPosterPhotoInProgress: false,
  setPosterPhotoError: null,
  videos: [],
  uploadVideosInProgress: false,
  uploadVideosError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        ...updateUploadedImagesState(state, payload),
        createListingDraftInProgress: false,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        publishingListing: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        publishingListing: null,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, ...updateUploadedImagesState(state, payload), updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SHOW_LISTINGS_REQUEST:
      return { ...state, showListingsError: null };
    case SHOW_LISTINGS_SUCCESS:
      return { ...state, images: {}, imageOrder: [], removedImageIds: [] };

    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showListingsError: payload, redirectToListing: false };

    case UPLOAD_IMAGE_REQUEST: {
      const files = payload.params;

      const requestedImageIds = files.map(f => f.id);
      const requestedImages = reduceImageIds.requested(files);

      const uploadedImages = {
        ...state.images,
        ...requestedImages,
      };

      return {
        ...state,
        images: uploadedImages,
        imageOrder: state.imageOrder.concat(requestedImageIds),
        uploadImageError: null,
        uploadImageErrorIds: [],
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      const { images, fileTempIds } = payload;

      const newImages = reduceImageIds.uploaded(images, fileTempIds);
      return { ...state, images: { ...state.images, ...newImages } };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const uploadedImagesOrder = state.imageOrder.filter(i => i !== id);

      return {
        ...state,
        imageOrder: uploadedImagesOrder,
        images: omit(state.images, id),
        uploadImageError: error,
        uploadImageErrorIds: [...state.uploadImageErrorIds, id],
      };
    }

    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    case SET_COVER_PHOTO_REQUEST:
      return { ...state, setCoverPhotoInProgress: true, setCoverPhotoError: null };
    case SET_COVER_PHOTO_SUCCESS:
      return {
        ...state,
        setCoverPhotoInProgress: false,
        setCoverPhotoError: null,
      };
    case SET_COVER_PHOTO_ERROR:
      return { ...state, setCoverPhotoInProgress: false, setCoverPhotoError: payload };

    case SET_POSTER_PHOTO_REQUEST:
      return { ...state, setPosterPhotoInProgress: true, setPosterPhotoError: null };
    case SET_POSTER_PHOTO_SUCCESS:
      return {
        ...state,
        setPosterPhotoInProgress: false,
        setPosterPhotoError: null,
      };
    case SET_POSTER_PHOTO_ERROR:
      return { ...state, setPosterPhotoInProgress: false, setPosterPhotoError: payload };

    case UPLOAD_VIDEOS_REQUEST:
      const combinedRequestVideos = state.videos.concat(payload.params.files);
      const sortedVideos = combinedRequestVideos.sort((x, y) =>
        x.invalid === y.invalid ? 0 : x.invalid ? 1 : -1
      );
      return {
        ...state,
        uploadVideosInProgress: true,
        uploadVideosError: null,
        videos: sortedVideos,
      };
    case UPLOAD_VIDEOS_SUCCESS:
      const prevVideos = state.videos.filter(video => !video.uploading && !video.invalid);
      const combinedSuccessVideos = payload.videos.concat(
        state.videos.filter(video => video.invalid)
      );
      return {
        ...state,
        uloadVideosInProgress: false,
        uploadVideosError: null,
        videos: [...prevVideos, ...combinedSuccessVideos],
      };
    case UPLOAD_VIDEOS_ERROR:
      return {
        ...state,
        uploadVideosInProgress: false,
        uploadVideosError: payload,
      };

    case REMOVE_LISTING_VIDEO:
      return {
        ...state,
        videos: state.videos.filter(video => video.id !== payload.videoId),
        removedVideoIds: [...state.removedVideoIds, payload.videoId],
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

export const removeListingVideo = videoId => ({
  type: REMOVE_LISTING_VIDEO,
  payload: { videoId },
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListings = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

export const setCoverPhotoRequest = requestAction(SET_COVER_PHOTO_REQUEST);
export const setCoverPhotoSuccess = successAction(SET_COVER_PHOTO_SUCCESS);
export const setCoverPhotoError = errorAction(SET_COVER_PHOTO_ERROR);

export const setPosterPhotoRequest = requestAction(SET_POSTER_PHOTO_REQUEST);
export const setPosterPhotoSuccess = successAction(SET_POSTER_PHOTO_SUCCESS);
export const setPosterPhotoError = errorAction(SET_POSTER_PHOTO_ERROR);

export const uploadVideosRequest = requestAction(UPLOAD_VIDEOS_REQUEST);
export const uploadVideosSuccess = successAction(UPLOAD_VIDEOS_SUCCESS);
export const uploadVideosError = errorAction(UPLOAD_VIDEOS_ERROR);

// ================ Thunk ================ //

export function requestShowListing(actionPayload, isOwnListing) {
  return async (dispatch, getState, sdk) => {
    dispatch(showListings(actionPayload));

    const integrationResponse = await integrationAPI.listings.show({
      ...actionPayload,
      id: actionPayload.id.uuid,
    });
    const currentListing = denormalisedResponseEntities(integrationResponse.data)[0];

    const { currentUser } = getState().user;
    const isOwn = isOwnListing || currentUser?.id?.uuid === currentListing.author.id.uuid;

    if (isOwn) {
      return sdk.ownListings
        .show(actionPayload)
        .then(response => {
          // EditListingPage fetches new listing data, which also needs to be added to global data
          dispatch(addMarketplaceEntities(response));
          dispatch(showListingsSuccess(response));
          return response;
        })
        .catch(e => dispatch(showListingsError(storableError(e))));
    } else {
      dispatch(addMarketplaceEntities(integrationResponse.data));
      dispatch(showListingsSuccess(integrationResponse.data));
      return integrationResponse.data;
    }
  };
}

export function requestCreateListingDraft(data) {
  return async (dispatch, getState, sdk) => {
    dispatch(createListingDraft(data));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .createDraft(data, queryParams)
      .then(response => {
        //const id = response.data.data.id.uuid;

        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListing(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      dispatch(publishListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload) {
  return async (dispatch, getState, sdk) => {
    const files = actionPayload;
    const fileIds = files.map(f => f.id);

    dispatch(uploadImage(files));
    const responses = files.map(file => {
      const queryParams = {
        expand: true,
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      };
      return sdk.images
        .upload({ image: file.file }, queryParams)
        .then(apiResponse => {
          return apiResponse;
        })
        .catch(e => {
          const errorResponse = { id: file.id, error: storableError(e) };
          dispatch(uploadImageError(errorResponse));
          return errorResponse;
        });
    });

    const promises = await Promise.all(responses);
    const validImages = promises.filter(p => !p.error);
    const images = validImages.map(i => i.data.data);

    dispatch(
      uploadImageSuccess({
        data: {
          images,
          fileTempIds: fileIds,
        },
      })
    );
  };
}

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateListing(tab, data, isOwnListing) {
  return async (dispatch, getState, sdk) => {
    dispatch(updateListing(data));

    const { id } = data;
    let updateResponse;

    const useSdk = isOwnListing ? sdk.ownListings : integrationAPI.listings;

    return useSdk
      .update(data, { expand: true })
      .then(response => {
        updateResponse = response;
        const payload = {
          id,
          include: ['author', 'images'],
          'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        };
        return dispatch(requestShowListing(payload, isOwnListing));
      })
      .then(() => {
        dispatch(markTabUpdated(tab));
        dispatch(updateListingSuccess(updateResponse));
        return updateResponse;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        dispatch(updateListingError(storableError(e)));
        throw e;
      });
  };
}

export const setCoverPhoto = (listingId, imageId, isOwnListing) => (dispatch, getState, sdk) => {
  dispatch(setCoverPhotoRequest());

  const useSdk = isOwnListing ? sdk.ownListings : integrationAPI.listings;

  return useSdk
    .update(
      {
        id: listingId.uuid,
        publicData: {
          coverId: imageId ? imageId.uuid : null,
        },
      },
      { expand: true }
    )
    .then(updateResponse => {
      dispatch(addMarketplaceEntities(updateResponse));
      dispatch(setCoverPhotoSuccess(updateResponse));

      return updateResponse;
    })
    .catch(e => dispatch(setCoverPhotoError(storableError(e))));
};

export const setPosterPhoto = (listingId, imageId, isOwnListing) => (dispatch, getState, sdk) => {
  dispatch(setPosterPhotoRequest());

  const useSdk = isOwnListing ? sdk.ownListings : integrationAPI.listings;

  return useSdk
    .update(
      {
        id: listingId.uuid,
        publicData: {
          posterId: imageId ? imageId.uuid : null,
        },
      },
      { expand: true }
    )
    .then(updateResponse => {
      dispatch(addMarketplaceEntities(updateResponse));
      dispatch(setPosterPhotoSuccess(updateResponse));

      return updateResponse;
    })
    .catch(e => dispatch(setPosterPhotoError(storableError(e))));
};

export const requestVideosUpload = actionPayload => async (dispatch, getState, sdk) => {
  const files = actionPayload;
  const validFiles = files.filter(file => !file.invalid);
  const invalidFiles = files.filter(file => file.invalid);

  dispatch(uploadVideosRequest({ files: validFiles.concat(invalidFiles) }));

  try {
    const promises = validFiles.map(async file => await dispatch(uploadVideo(file.file)));
    const videos = validFiles.length > 0 ? await Promise.all(promises) : [];

    dispatch(uploadVideosSuccess({ data: { videos } }));
    return videos;
  } catch (e) {
    dispatch(uploadVideosError(storableError(e)));
  }
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());

  return dispatch(fetchOrganizationProfiles()).then(async () => {
    const { id, type } = params;

    if (type === 'new') {
      // No need to listing data when creating a new listing
      try {
        const response = await Promise.all([dispatch(fetchCurrentUser())]);
        const currentUser = getState().user.currentUser;
        if (currentUser && currentUser.stripeAccount) {
          dispatch(fetchStripeAccount());
        }
        return response;
      } catch (error) {
        throw error;
      }
    }

    const payload = {
      id: new UUID(id),
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    try {
      const response = await Promise.all([
        dispatch(requestShowListing(payload)),
        dispatch(fetchCurrentUser()),
        dispatch(searchListings({ pub_type: config.listingTypes['show'], perPage: 100 })),
        dispatch(showListingInvites(id)),
      ]);
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }
      return response;
    } catch (error) {
      throw error;
    }
  });
};
