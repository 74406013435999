import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, FieldTextInput, PrimaryButton, Avatar, SecondaryButton } from '../../components';

import css from './CommentForm.module.css';

const CommentFormComponent = props => {
  const [isFocused, setFocused] = useState(false);
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          className,
          rootClassName,
          disabled,
          handleSubmit,
          intl,
          formId,
          invalid,
          form,
          pristine,
          values,
          onCancel,
          currentUser,
          submitMessageTranslationId,
          sendCommentError,
          sendCommentInProgress,
        } = fieldRenderProps;

        const commentPlaceholderMessage = intl.formatMessage({
          id: 'CommentForm.commentPlaceholder',
        });

        const errorMessage = sendCommentError ? (
          <p className={css.error}>
            <FormattedMessage id="CommentForm.commentSubmitFailed" />
          </p>
        ) : null;

        const cancelMessage = intl.formatMessage({
          id: 'CommentForm.commentCancel',
        });
        const submitMessage = intl.formatMessage({
          id: submitMessageTranslationId ? submitMessageTranslationId : 'CommentForm.commentSubmit',
        });

        const isInputFocused =
          typeof fieldRenderProps.isFocused === 'undefined'
            ? isFocused
            : fieldRenderProps.isFocused;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = sendCommentInProgress;
        const submitDisabled =
          invalid || pristine || disabled || submitInProgress || !values.comment;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.commentWrapper}>
              <Avatar user={currentUser} disableProfileLink />
              <FieldTextInput
                className={css.comment}
                inputRootClass={css.commentTextarea}
                type="textarea"
                id={formId ? `${formId}.comment` : 'comment'}
                name="comment"
                placeholder={commentPlaceholderMessage}
                onFocus={() => setFocused(true)}
              />
            </div>

            {errorMessage}
            {isInputFocused ? (
              <div className={css.formButtons}>
                <SecondaryButton
                  className={css.cancelButton}
                  type="button"
                  onClick={() => {
                    setFocused(false);
                    onCancel(values, form);
                  }}
                >
                  {cancelMessage}
                </SecondaryButton>
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  {submitMessage}
                </PrimaryButton>
              </div>
            ) : null}
          </Form>
        );
      }}
    />
  );
};

CommentFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  sendCommentError: null,
};

const { bool, func, string } = PropTypes;

CommentFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  sendCommentError: propTypes.error,
  sendCommentInProgress: bool.isRequired,
};

const CommentForm = compose(injectIntl)(CommentFormComponent);
CommentForm.displayName = 'CommentForm';

export default CommentForm;
