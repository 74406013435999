import React from 'react';
import { array, string, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { ensureListing, getUpcomingExceptions, userDisplayNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import { IconPremium, NamedLink, ResponsiveImage } from '..';
import { AVAILABILITY_EXCEPTION_METADATA_ACCESS_MEMBERS } from '../../util/types';
import config from '../../config';
import moment from 'moment';
import classNames from 'classnames';

import css from './ExperienceCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const getThemeInfo = (themeOptions, key) => {
  return themeOptions.find(c => c.key === key);
};

const formatExceptionDateTime = timestamp => {
  return moment(parseInt(timestamp, 10)).format('MMM D @h:mm A');
};

const ListingImage = props => <ResponsiveImage {...props} />;
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ExperienceCardComponent = props => {
  const {
    className,
    rootClassName,
    aspectWrapperClassName,
    listing,
    renderSizes,
    filtersConfig,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const currentAuthor = currentListing ? currentListing.author : null;
  const authorDisplayName = userDisplayNameAsString(currentAuthor);

  const id = currentListing.id.uuid;
  const { title = '', publicData, metadata } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const themeOptions = findOptionsForSelectFilter('theme', filtersConfig);
  const theme = publicData ? getThemeInfo(themeOptions, publicData.theme) : null;

  const show = publicData.show;
  const subscriptions = metadata?.subscriptions ? metadata.subscriptions.length : 0;

  const upcomingAvailabilityExceptions = getUpcomingExceptions(
    listing.attributes.publicData.exceptions,
    2
  );
  const hasUpcomingAvailabilityExceptions = upcomingAvailabilityExceptions.length > 0;

  return (
    <NamedLink className={classes} name="ExperiencePage" params={{ id, slug }}>
      <div className={css.threeToTwoWrapper}>
        <div className={classNames(css.aspectWrapper, aspectWrapperClassName)}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        <div className={css.subscriptions}>
          <FormattedMessage
            id="ExperienceCard.subscriptions"
            values={{
              subscriptions: <span className={css.subscriptionsCount}>{subscriptions}</span>,
              subscriptionLength: subscriptions,
            }}
          />
        </div>
        <div className={css.author}>
          <FormattedMessage
            id="ExperienceCard.author"
            values={{
              displayName: <span className={css.authorName}>{authorDisplayName}</span>,
            }}
          />
        </div>
        <div className={css.details}>
          {theme ? (
            <span className={classNames(css.detailsItem, css.strong)}>{theme.label}</span>
          ) : null}
          {show ? (
            <span className={css.detailsItem}>
              <FormattedMessage
                id="ExperienceCard.show"
                values={{ name: <strong className={css.strong}>{show.name}</strong> }}
              />
            </span>
          ) : null}
        </div>
        {hasUpcomingAvailabilityExceptions ? (
          <div className={css.availabilityExceptionsContainer}>
            <div className={css.availabilityExceptionsHeading}>
              <FormattedMessage id="ExperienceCard.availabilityExceptionsHeading" />
            </div>
            <div className={css.availabilityExceptions}>
              {upcomingAvailabilityExceptions.map(e => {
                const isForMembers =
                  e.attributes.access === AVAILABILITY_EXCEPTION_METADATA_ACCESS_MEMBERS;
                return (
                  <span key={e.id} className={css.availabilityException}>
                    {isForMembers ? (
                      <IconPremium className={css.availabilityExceptionIcon} />
                    ) : null}
                    {formatExceptionDateTime(e.attributes.start)}
                  </span>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </NamedLink>
  );
};

ExperienceCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  aspectWrapperClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
};

ExperienceCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  aspectWrapperClassName: string,
  filtersConfig: array,

  listing: object.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
};

export default ExperienceCardComponent;
