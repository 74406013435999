import React, { useState } from 'react';
import { oneOfType, object, bool, func, shape, string, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  Form,
  EditWizardButton,
  PrimaryButton,
  InlineTextButton,
  IconArrow,
  PreviewListingButton,
} from '../../components';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import EditExperienceMembershipsField from './EditExperienceMembershipsField';
import css from './EditExperienceMembershipForm.module.css';

const StripeRequirementsMissing = props => {
  const { isMembershipView, payoutEnabled, onOpenPayoutDetailsModal } = props;
  return !isMembershipView && !payoutEnabled ? (
    <div className={css.stripeRequirementsMissing}>
      <p className={css.stripeRequirementsMissingMessage}>
        <FormattedMessage id="EditExperienceMembershipForm.verificationNeededText" />
      </p>
      <PrimaryButton
        type="button"
        className={css.getVerifiedButton}
        onClick={onOpenPayoutDetailsModal}
      >
        <FormattedMessage id="EditExperienceMembershipForm.getVerifiedButton" />
      </PrimaryButton>
    </div>
  ) : null;
};

const EditExperienceMembershipFormComponent = props => {
  // Keep track of active memberships which allows user to
  // edit single membership.
  const [activeMembershipIndex, setActiveMembershipIndex] = useState(null);
  const [formUpdated, setFormUpdated] = useState(false);
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          invalid,
          pristine,
          form,
          values,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          panelTitle,
          listing,
          isCurrentUserAuthor,
          payoutEnabled,
          onOpenPayoutDetailsModal,
          onRedirectToPreviousTab,
          intl,
        } = formRenderProps;

        const { push } = form.mutators;
        const membershipsFromValues = values.memberships;

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditExperienceMembershipForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditExperienceMembershipForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready || formUpdated;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const isMembershipView = activeMembershipIndex !== null;

        return (
          <Form
            className={classes}
            onSubmit={async values => {
              setFormUpdated(false);
              await handleSubmit(values);

              // Notify the component that the form
              // has been updated
              setFormUpdated(true);
            }}
          >
            <div className={css.content}>
              <div className={css.contentWrapper}>
                <h1 className={css.title}>{panelTitle}</h1>
                <p className={css.subTitle}>
                  <FormattedMessage id="EditExperienceMembershipForm.subTitle" />
                </p>

                {isCurrentUserAuthor ? (
                  <StripeRequirementsMissing
                    isMembershipView={isMembershipView}
                    payoutEnabled={payoutEnabled}
                    onOpenPayoutDetailsModal={onOpenPayoutDetailsModal}
                  />
                ) : null}

                <div className={css.membershipsWrapper}>
                  {!isMembershipView ? (
                    <PrimaryButton
                      type="button"
                      className={css.addMembershipButton}
                      onClick={() => {
                        push('memberships', {
                          perks: ['members-only-live-event'],
                        });
                        setActiveMembershipIndex(membershipsFromValues.length);
                      }}
                    >
                      <FormattedMessage id="EditExperienceMembershipForm.addMembership" />
                    </PrimaryButton>
                  ) : (
                    <InlineTextButton
                      type="button"
                      className={css.backToMembershipsButton}
                      onClick={() => setActiveMembershipIndex(null)}
                    >
                      <IconArrow className={css.backIcon} />
                      <FormattedMessage id="EditExperienceMembershipForm.back" />
                    </InlineTextButton>
                  )}
                  <EditExperienceMembershipsField
                    intl={intl}
                    push={push}
                    isMembershipView={isMembershipView}
                    membershipsFromValues={membershipsFromValues}
                    activeMembershipIndex={activeMembershipIndex}
                    setActiveMembershipIndex={setActiveMembershipIndex}
                  />
                </div>

                {errorMessageUpdateListing}
                {errorMessageShowListing}
              </div>
            </div>

            <div className={css.submitButtonRoot}>
              <EditWizardButton
                className={css.backButton}
                type="button"
                onClick={onRedirectToPreviousTab}
              >
                <FormattedMessage id="EditExperienceContentForm.backButton" />
              </EditWizardButton>
              <div className={css.submitAndPreviewButton}>
                <PreviewListingButton listing={listing} />
                <EditWizardButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </EditWizardButton>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditExperienceMembershipFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  isCurrentUserAuthor: false,
  panelTitle: null,
};

EditExperienceMembershipFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  panelTitle: oneOfType([object, node]),
  isCurrentUserAuthor: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditExperienceMembershipFormComponent);
