import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { resetToStartOfDay, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import config from '../../config';
import { integrationAPI } from '../../util/api';
import {
  getSelectedOrganizationProfile,
  getSelectedOrganizationProfileIdFromExtendedData,
} from '../../util/organizations';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

const { Money } = sdkTypes;

const getSubscriptionUsers = subscriptions =>
  subscriptions.filter(s => s.userId).map(s => s.userId);

// ================ Action types ================ //

export const FETCH_SUBSCRIPTIONS_AND_DONATIONS_REQUEST =
  'app/AnalyticsPage/FETCH_SUBSCRIPTIONS_AND_DONATIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_AND_DONATIONS_SUCCESS =
  'app/AnalyticsPage/FETCH_SUBSCRIPTIONS_AND_DONATIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_AND_DONATIONS_ERROR =
  'app/AnalyticsPage/FETCH_SUBSCRIPTIONS_AND_DONATIONS_ERROR';

export const FETCH_REVENUE_REQUEST = 'app/AnalyticsPage/FETCH_REVENUE_REQUEST';
export const FETCH_REVENUE_SUCCESS = 'app/AnalyticsPage/FETCH_REVENUE_SUCCESS';
export const FETCH_REVENUE_ERROR = 'app/AnalyticsPage/FETCH_REVENUE_ERROR';

export const FETCH_EPISODES_REQUEST = 'app/AnalyticsPage/FETCH_EPISODES_REQUEST';
export const FETCH_EPISODES_SUCCESS = 'app/AnalyticsPage/FETCH_EPISODES_SUCCESS';
export const FETCH_EPISODES_ERROR = 'app/AnalyticsPage/FETCH_EPISODES_ERROR';

export const FETCH_SUBSCRIPTION_USERS_REQUEST =
  'app/AnalyticsPage/FETCH_SUBSCRIPTION_USERS_REQUEST';
export const FETCH_SUBSCRIPTION_USERS_SUCCESS =
  'app/AnalyticsPage/FETCH_SUBSCRIPTION_USERS_SUCCESS';
export const FETCH_SUBSCRIPTION_USERS_ERROR = 'app/AnalyticsPage/FETCH_SUBSCRIPTION_USERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchSubscriptionsAndDonationsInProgress: false,
  fetchSubscriptionsAndDonationsError: null,
  fetchRevenueInProgress: false,
  fetchRevenueError: null,
  fetchEpisodesInProgress: false,
  fetchEpisodesError: null,
  fetchSubscriptionUsersInProgress: false,
  fetchSubscriptionUsersError: null,
  episodes: [],
  subscriptionUserIds: [],

  // data
  analyticsData: {
    subscriptions: 0,
    donations: 0,
    liveRooms: 0,
    revenue: new Money(0, config.currency),
  },
};

const resultIds = data => data.data.map(l => l.id);

export default function analyticsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    // FETCH_SUBSCRIPTIONS_AND_DONATIONS
    case FETCH_SUBSCRIPTIONS_AND_DONATIONS_REQUEST:
      return {
        ...state,
        fetchSubscriptionsAndDonationsInProgress: true,
        fetchSubscriptionsAndDonationsError: null,
      };
    case FETCH_SUBSCRIPTIONS_AND_DONATIONS_SUCCESS:
      return {
        ...state,
        fetchSubscriptionsAndDonationsInProgress: false,
        fetchSubscriptionsAndDonationsError: null,
        analyticsData: {
          ...state.analyticsData,
          subscriptions: payload.subscriptions,
          donations: payload.donations,
          liveRooms: payload.liveRooms,
        },
      };
    case FETCH_SUBSCRIPTIONS_AND_DONATIONS_ERROR:
      return {
        ...state,
        fetchSubscriptionsAndDonationsInProgress: false,
        fetchSubscriptionsAndDonationsError: null,
      };

    // FETCH_REVENUE
    case FETCH_REVENUE_REQUEST:
      return {
        ...state,
        fetchRevenueInProgress: true,
        fetchRevenueError: null,
      };
    case FETCH_REVENUE_SUCCESS:
      return {
        ...state,
        fetchRevenueInProgress: false,
        fetchRevenueError: null,
        analyticsData: {
          ...state.analyticsData,
          revenue: payload.revenue,
        },
      };
    case FETCH_REVENUE_ERROR:
      return {
        ...state,
        fetchRevenueInProgress: false,
        fetchRevenueError: null,
      };

    // FETCH_EPISODES
    case FETCH_EPISODES_REQUEST:
      return {
        ...state,
        fetchEpisodesInProgress: true,
        fetchEpisodesError: null,
      };
    case FETCH_EPISODES_SUCCESS:
      return {
        ...state,
        fetchEpisodesInProgress: false,
        fetchEpisodesError: null,
        episodes: payload.episodes,
      };
    case FETCH_EPISODES_ERROR:
      return {
        ...state,
        fetchEpisodesInProgress: false,
        fetchEpisodesError: payload,
      };

    // FETCH_SUBSCRIPTION_USERS
    case FETCH_SUBSCRIPTION_USERS_REQUEST:
      return {
        ...state,
        fetchSubscriptionUsersInProgress: true,
        fetchSubscriptionUsersError: null,
      };
    case FETCH_SUBSCRIPTION_USERS_SUCCESS:
      return {
        ...state,
        fetchSubscriptionUsersInProgress: false,
        fetchSubscriptionUsersError: null,
        subscriptionUserIds: resultIds(payload.data).filter(id =>
          payload.userIds.includes(id.uuid)
        ),
      };
    case FETCH_SUBSCRIPTION_USERS_ERROR:
      return {
        ...state,
        fetchSubscriptionUsersInProgress: false,
        fetchSubscriptionUsersError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchSubscriptionsAndDonationsRequest = () => ({
  type: FETCH_SUBSCRIPTIONS_AND_DONATIONS_REQUEST,
});
export const fetchSubscriptionsAndDonationsSuccess = data => ({
  type: FETCH_SUBSCRIPTIONS_AND_DONATIONS_SUCCESS,
  payload: {
    subscriptions: data.subscriptions,
    donations: data.donations,
    liveRooms: data.liveRooms,
  },
});
export const fetchSubscriptionsAndDonationsError = e => ({
  type: FETCH_SUBSCRIPTIONS_AND_DONATIONS_ERROR,
  error: true,
  payload: e,
});

export const fetchRevenueRequest = () => ({
  type: FETCH_REVENUE_REQUEST,
});
export const fetchRevenueSuccess = revenue => ({
  type: FETCH_REVENUE_SUCCESS,
  payload: {
    revenue,
  },
});
export const fetchRevenueError = e => ({
  type: FETCH_REVENUE_ERROR,
  error: true,
  payload: e,
});

export const fetchEpisodesRequest = () => ({
  type: FETCH_EPISODES_REQUEST,
});
export const fetchEpisodesSuccess = episodes => ({
  type: FETCH_EPISODES_SUCCESS,
  payload: { episodes },
});
export const fetchEpisodesError = e => ({
  type: FETCH_EPISODES_ERROR,
  error: true,
  payload: e,
});

export const fetchSubscriptionUsersRequest = () => ({
  type: FETCH_SUBSCRIPTION_USERS_REQUEST,
});
export const fetchSubscriptionUsersSuccess = (response, userIds) => ({
  type: FETCH_SUBSCRIPTION_USERS_SUCCESS,
  payload: { data: response.data, userIds },
});
export const fetchSubscriptionUsersError = e => ({
  type: FETCH_SUBSCRIPTION_USERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const requestFetchSubscriptionUsers = userIds => (dispatch, getState, sdk) => {
  dispatch(fetchSubscriptionUsersRequest());

  return integrationAPI.users
    .query({
      include: ['profileImage'],
      'fields.image': [
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(fetchSubscriptionUsersSuccess(response.data, userIds));

      return response;
    })
    .catch(e => dispatch(fetchSubscriptionUsersError(storableError(e))));
};

export const fetchSubscriptionsAndDonations = selectedOrganization => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchSubscriptionsAndDonationsRequest());

  return sdk.currentUser.show().then(userResponse => {
    const authorId = userResponse.data.data.id;

    return sdk.listings
      .query({
        authorId: selectedOrganization.creatorId ? selectedOrganization.creatorId : authorId,
        pub_type: config.listingTypes['experience'],
        pub_organizationId: selectedOrganization.id,
      })
      .then(response => {
        const listings = response.data.data;

        const listingSubscriptions = listings
          .filter(l => l.attributes.metadata.subscriptions)
          .map(l => l.attributes.metadata.subscriptions)
          .flat(1);
        const listingDonations = listings
          .filter(l => l.attributes.metadata.donations)
          .map(l => l.attributes.metadata.donations)
          .flat(1);

        const data = {
          subscriptions: listingSubscriptions.length,
          donations: listingDonations.length,
          liveRooms: listings.length,
        };
        const subscriptionUserIds = getSubscriptionUsers(listingSubscriptions);

        dispatch(addMarketplaceEntities(response));
        dispatch(fetchSubscriptionsAndDonationsSuccess(data));
        dispatch(requestFetchSubscriptionUsers(subscriptionUserIds));

        return data;
      })
      .catch(e => dispatch(fetchSubscriptionsAndDonationsError(storableError(e))));
  });
};

export const fetchRevenueWithIntegrationAPI = selectedOrganization => (dispatch, getState, sdk) => {
  dispatch(fetchRevenueRequest());

  return integrationAPI.transactions
    .query({
      providerId: selectedOrganization.creatorId,
      include: ['listing'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));

      const denormaliseResponseEntities = denormalisedResponseEntities(response.data);
      const transactions = denormaliseResponseEntities.filter(
        t => t?.listing?.attributes?.publicData?.organizationId === selectedOrganization.id
      );

      const combinedRevenue = transactions
        .filter(t => t.attributes.payoutTotal)
        .map(t => t.attributes.payoutTotal);
      const totalRevenue = combinedRevenue.reduce((sum, moneyObj) => sum + moneyObj.amount, 0);
      const revenue = new Money(totalRevenue, config.currency);

      dispatch(fetchRevenueSuccess(revenue));
      return revenue;
    })
    .catch(e => dispatch(fetchRevenueError(storableError(e))));
};

export const fetchRevenue = organizationId => (dispatch, getState, sdk) => {
  dispatch(fetchRevenueRequest());

  return sdk.transactions
    .query({
      only: 'sale',
      include: ['listing'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      const denormaliseResponseEntities = denormalisedResponseEntities(response);
      const transactions = denormaliseResponseEntities.filter(
        t => t?.listing?.attributes?.publicData?.organizationId === organizationId
      );

      const combinedRevenue = transactions
        .filter(t => t.attributes.payoutTotal)
        .map(t => t.attributes.payoutTotal);
      const totalRevenue = combinedRevenue.reduce((sum, moneyObj) => sum + moneyObj.amount, 0);
      const revenue = new Money(totalRevenue, config.currency);

      dispatch(fetchRevenueSuccess(revenue));
      return revenue;
    })
    .catch(e => dispatch(fetchRevenueError(storableError(e))));
};

export const fetchEpisodes = selectedOrganization => (dispatch, getState, sdk) => {
  dispatch(fetchEpisodesRequest());

  // 1. Query currentUser
  return sdk.currentUser
    .show()
    .then(userResponse => {
      const authorId = userResponse.data.data.id.uuid;

      // 2. Query experience listings
      return sdk.listings
        .query({
          authorId: selectedOrganization.creatorId ? selectedOrganization.creatorId : authorId,
          pub_type: config.listingTypes['experience'],
          pub_organizationId: selectedOrganization.id,
        })
        .then(async listingsResponse => {
          const listings = listingsResponse.data.data;

          // 3. Map through experience listings and
          // query availability exceptions
          const listingAndAvailabilityExceptions = await Promise.all(
            listings.map(l => {
              const tz = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

              const today = new Date();
              const start = resetToStartOfDay(today, tz, 0);
              // Query range: today + 364 days
              const exceptionRange = 364;
              const end = resetToStartOfDay(today, tz, exceptionRange);

              const fetchParams = {
                listingId: l.id.uuid,
                start,
                end,
              };

              return integrationAPI.availabilityExceptions.query(fetchParams).then(response => {
                const availabilityExceptions = denormalisedResponseEntities(response.data);

                return {
                  listing: l,
                  availabilityExceptions,
                };
              });
            })
          );

          const episodes = listingAndAvailabilityExceptions.flatMap(item =>
            item.availabilityExceptions.map(exception => ({
              ...exception,
              listing: item.listing,
            }))
          );

          dispatch(fetchEpisodesSuccess(episodes));
          return episodes;
        })
        .catch(e => dispatch(fetchEpisodesError(storableError(e))));
    })
    .catch(e => dispatch(fetchEpisodesError(storableError(e))));
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const currentUser = await dispatch(fetchCurrentUser());
  const organizationProfiles = await dispatch(fetchOrganizationProfiles());

  const selectedOrganizationProfileId = getSelectedOrganizationProfileIdFromExtendedData(
    currentUser
  );
  const selectedOrganization = getSelectedOrganizationProfile(
    organizationProfiles,
    selectedOrganizationProfileId
  );

  return Promise.all([
    dispatch(fetchSubscriptionsAndDonations(selectedOrganization)),
    selectedOrganization.invited
      ? dispatch(fetchRevenueWithIntegrationAPI(selectedOrganization))
      : dispatch(fetchRevenue(selectedOrganization.id)),
    dispatch(fetchEpisodes(selectedOrganization)),
  ]);
};
