import React, { Component } from 'react';
import { array, bool, func, shape, string, object, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { getVideoDuration } from '../../util/video';
import { CONTENT_TAB_VIDEOS, propTypes } from '../../util/types';
import { imagesRequired } from '../../util/validators';
import config from '../../config';
import { parse } from '../../util/urlHelpers';
import {
  Form,
  FieldDropzone,
  AddImages,
  AddVideos,
  IconPhoto,
  IconPhotos,
  IconVideo,
  IconVideos,
  InlineTextButton,
  EditWizardButton,
  ValidationError,
  PreviewListingButton,
  RecommendedSizes,
} from '../../components';
import UploadButton from './UploadButton';

// react-tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './react-tabs.css';

import css from './EditListingContentForm.module.css';

// Accept file types
const ACCEPT_IMAGES = 'image/*';
const ACCEPT_VIDEOS = 'video/mp4,video/x-m4v,video/*';
const ACCEPT_IMAGES_DROPZONE = {
  'image/*': ['.jpeg', '.jpg', '.png'],
};
const ACCEPT_VIDEOS_DROPZONE = {
  'video/*': ['.mp4', '.mov', '.avi', '.webm'],
};

// Field settings
const ACCEPT_MULTIPLE_FILES = true;
const MINIMUM_IMAGES = 1;

const TAB_IMAGES_INDEX = 0;
const TAB_VIDEOS_INDEX = 1;

export class EditListingContentFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
      videosUploadRequested: false,
      isImageDragActive: false,
      isVideoDragActive: false,
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.onVideosUploadHandler = this.onVideosUploadHandler.bind(this);
    this.submittedImages = [];
    this.submittedVideos = [];

    this.dropzoneImageRef = React.createRef();
    this.dropzoneVideoRef = React.createRef();
  }

  onImageUploadHandler(files) {
    if (files) {
      this.setState({ imageUploadRequested: true });

      const imageFiles = Object.values(files).map(file => ({
        id: `${file.name}_${Date.now()}`,
        file,
      }));
      this.props
        .onImageUpload(imageFiles)
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  async onVideosUploadHandler(files) {
    if (files) {
      this.setState({ videosUploadRequested: true });

      const videoFiles = Object.values(files).map(file => {
        return new Promise(async resolve => {
          const duration = await getVideoDuration(file);
          if (duration >= config.videoSettings.maxDuration) {
            resolve({
              id: `${file.name}_${Date.now()}`,
              file,
              invalid: true,
            });
          } else {
            resolve({
              id: `${file.name}_${Date.now()}`,
              file,
              uploading: true,
            });
          }
        });
      });
      const promisedVideoFiles = await Promise.all(videoFiles);

      this.props
        .onVideosUpload(promisedVideoFiles)
        .then(() => {
          this.setState({ videosUploadRequested: false });
        })
        .catch(() => {
          this.setState({ videosUploadRequested: false });
        });
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        onVideosUploadHandler={this.onVideosUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        videosUploadRequested={this.state.videosUploadRequested}
        initialValues={{ images: this.props.images, videos: this.props.videos }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            location,
            images,
            imageUploadRequested,
            videos,
            videosUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onVideosUploadHandler,
            onRemoveImage,
            onRemoveVideo,
            onSetCoverPhoto,
            onSetPosterPhoto,
            onUpdateImages,
            setCoverPhotoInProgress,
            setCoverPhotoError,
            setPosterPhotoInProgress,
            setPosterPhotoError,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            panelTitle,
            videosPanelTitle,
            listing,
            onRedirectToPreviousTab,
          } = formRenderProps;

          const { activeTab } = parse(location.search);
          const defaultTabIndex =
            activeTab === CONTENT_TAB_VIDEOS ? TAB_VIDEOS_INDEX : TAB_IMAGES_INDEX;

          const hasImages = images?.length > 0;
          const hasMinimumImages = images.length >= MINIMUM_IMAGES;
          const hasVideos = videos?.length > 0;

          // Image field label & required message
          const chooseImageText = (
            <span className={css.chooseFileText}>
              <IconPhotos className={css.imagesIcon} />
              <span className={css.chooseFile}>
                <FormattedMessage id="EditListingContentForm.chooseImage" />
              </span>
              <span className={css.fileTypes}>
                <FormattedMessage id="EditListingContentForm.imageTypes" />
              </span>
              <InlineTextButton
                type="button"
                className={css.chooseFileButton}
                onClick={() => {
                  this.dropzoneImageRef?.open();
                }}
              >
                <FormattedMessage id="EditListingContentForm.chooseImageButton" />
              </InlineTextButton>
            </span>
          );
          const dragImageText = (
            <span className={css.chooseFileText}>
              <IconPhotos className={css.imagesIcon} />
              <span className={css.chooseFile}>
                <FormattedMessage id="EditListingContentForm.dragImage" />
              </span>
            </span>
          );
          const imageLabelText = hasImages ? (
            <IconPhoto className={css.imageIcon} />
          ) : this.state.isImageDragActive ? (
            dragImageText
          ) : (
            chooseImageText
          );

          // Video field label
          const chooseVideoText = (
            <span className={css.chooseFileText}>
              <IconVideos className={css.videosIcon} />
              <span className={css.chooseFile}>
                <FormattedMessage id="EditListingContentForm.chooseVideo" />
              </span>
              <span className={css.fileTypes}>
                <FormattedMessage id="EditListingContentForm.videoTypes" />
              </span>
              <InlineTextButton
                type="button"
                className={css.chooseFileButton}
                onClick={() => this.dropzoneVideoRef?.open()}
              >
                <FormattedMessage id="EditListingContentForm.chooseVideoButton" />
              </InlineTextButton>
            </span>
          );
          const dragVideoText = (
            <span className={css.chooseFileText}>
              <IconVideos className={css.videosIcon} />
              <span className={css.chooseFile}>
                <FormattedMessage id="EditListingContentForm.dragImage" />
              </span>
            </span>
          );
          const videoLabelText = hasVideos ? (
            <IconVideo className={css.videoIcon} />
          ) : this.state.isVideoDragActive ? (
            dragVideoText
          ) : (
            chooseVideoText
          );

          const { updateListingError, uploadImageError, uploadImageErrorIds } = fetchErrors || {};

          let uploadImageFailed = null;
          if (uploadImageError) {
            const moreThenOneError = uploadImageErrorIds.length > 1;
            const errorIds = <span className={css.errorIds}>{uploadImageErrorIds.join(', ')}</span>;
            const errorMessageId = moreThenOneError
              ? 'EditListingContentForm.imageUploadFailed.multipleUploadFailed'
              : 'EditListingContentForm.imageUploadFailed.uploadFailed';
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id={errorMessageId} values={{ errorIds }} />
              </p>
            );
          }
          const updateListingFailed = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingContentForm.updateFailed" />
            </p>
          ) : null;

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingContentForm.imageRequired',
          });
          const uploadButtonTitleMessage =
            images.length >= MINIMUM_IMAGES
              ? intl.formatMessage({ id: 'EditListingContentForm.uploadImages' })
              : intl.formatMessage(
                  { id: 'EditListingContentForm.uploadMoreImages' },
                  { count: MINIMUM_IMAGES - images.length }
                );

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            ready ||
            !hasMinimumImages;

          const addImagesClassName = hasImages ? css.imagesField : css.noFiles;
          const addVideosClassName = hasVideos ? css.videosField : css.noFiles;
          const classes = classNames(css.root, className);

          const recommendedImageSizes = [
            {
              key: 'recommended-image-size-1',
              text: intl.formatMessage({
                id: 'EditListingContentForm.recommendedImageSize1',
              }),
            },
            {
              key: 'recommended-image-size-2',
              text: intl.formatMessage({
                id: 'EditListingContentForm.recommendedImageSize2',
              }),
            },
          ];

          const recommendedVideoSizes = [
            {
              key: 'recommended-video-size-1',
              text: intl.formatMessage({
                id: 'EditListingContentForm.recommendedVideoSize1',
              }),
            },
            {
              key: 'recommended-video-size-2',
              text: intl.formatMessage({
                id: 'EditListingContentForm.recommendedVideoSize2',
              }),
            },
          ];

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                this.submittedVideos = videos;
                handleSubmit(e);
              }}
            >
              <div className={css.content}>
                <div className={css.contentWrapper}>
                  <Tabs defaultIndex={defaultTabIndex}>
                    <TabList>
                      <Tab>
                        <span className={css.tabHeading}>
                          <IconPhoto className={css.tabIcon} />
                          <FormattedMessage id="EditListingContentForm.tabTitlePhotos" />
                        </span>
                      </Tab>
                      <Tab>
                        <span className={css.tabHeading}>
                          <IconVideo className={css.tabIcon} />
                          <FormattedMessage id="EditListingContentForm.tabTitleVideos" />
                        </span>
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <h1 className={css.title}>{panelTitle}</h1>
                      <p className={css.subTitle}>
                        <FormattedMessage id="EditListingContentForm.subTitle" />
                      </p>

                      {updateListingFailed}
                      {uploadImageFailed}

                      <UploadButton
                        show={hasImages}
                        title={uploadButtonTitleMessage}
                        buttonText={intl.formatMessage({
                          id: 'EditListingContentForm.uploadImagesButton',
                        })}
                        disabled={imageUploadRequested}
                        onClick={() => this.dropzoneImageRef?.open()}
                      />
                      <AddImages
                        className={addImagesClassName}
                        images={images}
                        listing={listing}
                        thumbnailClassName={css.thumbnail}
                        savedImageAltText={intl.formatMessage({
                          id: 'EditListingContentForm.savedImageAltText',
                        })}
                        removeImageText={intl.formatMessage({
                          id: 'EditListingContentForm.removeImageText',
                        })}
                        onSetCover={onSetCoverPhoto}
                        onSetPoster={onSetPosterPhoto}
                        onRemoveImage={onRemoveImage}
                        onUpdateImages={onUpdateImages}
                        setCoverInProgress={setCoverPhotoInProgress}
                        setCoverError={setCoverPhotoError}
                        setPosterInProgress={setPosterPhotoInProgress}
                        setPosterError={setPosterPhotoError}
                      >
                        <FieldDropzone
                          id="addImages"
                          name="addImages"
                          accept={ACCEPT_IMAGES}
                          dropzoneAccept={ACCEPT_IMAGES_DROPZONE}
                          multiple={ACCEPT_MULTIPLE_FILES}
                          form={form}
                          type="file"
                          disabled={imageUploadRequested}
                          onChange={onImageUploadHandler}
                          dropzoneRef={ref => {
                            if (ref) {
                              this.dropzoneImageRef = ref;
                            }
                          }}
                          hasFiles={hasImages}
                          isDragActive={this.state.isImageDragActive}
                          onToggleDragActive={active =>
                            this.setState({ isImageDragActive: active })
                          }
                        >
                          {imageLabelText}
                        </FieldDropzone>
                        <Field
                          component={props => {
                            const { input, meta } = props;
                            return (
                              <div className={css.fieldRequiredWrapper}>
                                <input {...input} />
                                <ValidationError fieldMeta={meta} />
                              </div>
                            );
                          }}
                          name="images"
                          type="hidden"
                          validate={imagesRequired(imageRequiredMessage, MINIMUM_IMAGES)}
                        />
                      </AddImages>
                      <RecommendedSizes
                        className={css.recommendedSizes}
                        sizeItems={recommendedImageSizes}
                      />
                    </TabPanel>

                    <TabPanel>
                      <h1 className={css.title}>{videosPanelTitle}</h1>
                      <p className={css.subTitle}>
                        <FormattedMessage id="EditListingContentForm.videosSubTitle" />
                      </p>
                      <UploadButton
                        show={hasVideos}
                        title={intl.formatMessage({ id: 'EditListingContentForm.uploadVideos' })}
                        buttonText={intl.formatMessage({
                          id: 'EditListingContentForm.uploadVideosButton',
                        })}
                        disabled={videosUploadRequested}
                        onClick={() => this.dropzoneVideoRef?.open()}
                      />
                      <AddVideos
                        className={addVideosClassName}
                        videos={videos}
                        listing={listing}
                        removeVideoText={intl.formatMessage({
                          id: 'EditListingContentForm.removeVideoText',
                        })}
                        videosUploadRequested={videosUploadRequested}
                        onRemoveVideo={onRemoveVideo}
                      >
                        <FieldDropzone
                          id="addVideos"
                          name="addVideos"
                          aspectWrapperClassName={css.videoAspectWrapper}
                          accept={ACCEPT_VIDEOS}
                          dropzoneAccept={ACCEPT_VIDEOS_DROPZONE}
                          multiple={ACCEPT_MULTIPLE_FILES}
                          form={form}
                          type="file"
                          disabled={videosUploadRequested}
                          onChange={onVideosUploadHandler}
                          dropzoneRef={ref => {
                            if (ref) {
                              this.dropzoneVideoRef = ref;
                            }
                          }}
                          hasFiles={hasVideos}
                          isDragActive={this.state.isVideoDragActive}
                          onToggleDragActive={active =>
                            this.setState({ isVideoDragActive: active })
                          }
                        >
                          {videoLabelText}
                        </FieldDropzone>
                        <Field
                          component={props => {
                            const { input, meta } = props;
                            return (
                              <div className={css.fieldRequiredWrapper}>
                                <input {...input} />
                                <ValidationError fieldMeta={meta} />
                              </div>
                            );
                          }}
                          name="videos"
                          type="hidden"
                        />
                      </AddVideos>
                      <RecommendedSizes
                        className={css.recommendedSizes}
                        sizeItems={recommendedVideoSizes}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>

              <div className={css.submitButtonRoot}>
                <EditWizardButton
                  className={css.backButton}
                  type="button"
                  onClick={onRedirectToPreviousTab}
                >
                  <FormattedMessage id="EditListingContentForm.backButton" />
                </EditWizardButton>
                <div className={css.submitAndPreviewButton}>
                  <PreviewListingButton listing={listing} />
                  <EditWizardButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </EditWizardButton>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingContentFormComponent.defaultProps = {
  fetchErrors: null,
  images: [],
  videos: [],
  listing: null,
  panelTitle: null,
  videosPanelTitle: null,
  onSetCoverPhoto: null,
  onSetPosterPhoto: null,
  setCoverPhotoInProgress: false,
  setCoverPhotoError: null,
  setPosterPhotoInProgress: false,
  setPosterPhotoError: null,
  onRedirectToPreviousTab: null,
};

EditListingContentFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  videos: array,
  intl: intlShape.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  listing: object,
  panelTitle: node,
  videosPanelTitle: node,
  onRedirectToPreviousTab: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onRemoveImage: func.isRequired,
  onRemoveVideo: func.isRequired,
  onSetCoverPhoto: func.isRequired,
  onSetPosterPhoto: func.isRequired,
  setCoverPhotoInProgress: bool.isRequired,
  setCoverPhotoError: propTypes.error,
  setPosterPhotoInProgress: bool.isRequired,
  setPosterPhotoError: propTypes.error,
};

export default compose(injectIntl)(EditListingContentFormComponent);
