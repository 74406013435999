import React, { useState, useEffect } from 'react';
import { arrayOf, string, bool, func, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import {
  getSelectedOrganizationProfile,
  getSelectedOrganizationProfileIdFromExtendedData,
  prepareUpdatedOrganizationProfiles,
} from '../../util/organizations';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  DashboardWrapper,
  DashboardNav,
  Modal,
} from '../../components';
import { OrganizationSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { saveOrganizationProfiles } from './OrganizationSettingsPage.duck';
import { uploadImage } from '../../ducks/googleStorage.duck';

import RedirectBanner from './RedirectBanner';
import SuccessInviteModal from './SuccessInviteModal';
import css from './OrganizationSettingsPage.module.css';

export const ACTION_TYPE_ADD = 'add';
export const ACTION_TYPE_EDIT = 'edit';

const titleMsgId = actionType => {
  if (actionType === ACTION_TYPE_ADD) {
    return 'OrganizationSettingsPage.addTitle';
  } else if (actionType === ACTION_TYPE_EDIT) {
    return 'OrganizationSettingsPage.editTitle';
  } else {
    return 'OrganizationSettingsPage.title';
  }
};

export const OrganizationSettingsPageComponent = props => {
  const {
    intl,
    location,
    history,
    scrollingDisabled,
    currentUser,
    organizationProfiles,
    selectedOrganizationProfileId,
    listings,
    onSaveOrganizationProfiles,
    saveOrganizationProfilesInProgress,
    saveOrganizationProfilesError,
    uploadImageInProgress,
    onUploadImage,
    onManageDisableScrolling,
  } = props;

  // Keep track of acitive organization ID that affects rendering the
  // correct organization when the UI is in single organization view.
  const [activeOrganizationId, setActiveOrganizationId] = useState(null);
  const [actionType, setActionType] = useState(null);

  const [isInvalidDeleteModalOpen, toggleInvalidDeleteModal] = useState(false);
  const [isSuccessInviteModalOpen, setSuccessInviteModalOpen] = useState(false);

  useEffect(() => {
    const redirectedAfterInvite = location?.state?.redirectedAfterInvite;

    if (redirectedAfterInvite) {
      setSuccessInviteModalOpen(true);
    }
  }, []);

  const selectedOrganization = getSelectedOrganizationProfile(
    organizationProfiles,
    selectedOrganizationProfileId
  );
  const isSingleOrganizationView = activeOrganizationId !== null;

  const schemaTitle = intl.formatMessage({ id: 'OrganizationSettingsPage.schemaTitle' });
  const createOrUpdateSuccessMessage =
    actionType === ACTION_TYPE_ADD
      ? intl.formatMessage({ id: 'OrganizationSettingsPage.createProfileSuccessMessage' })
      : intl.formatMessage({ id: 'OrganizationSettingsPage.editProfileSuccessMessage' });

  const organizationSettingsTabs = [
    {
      text: <FormattedMessage id="OrganizationSettingsNav.organizationProfiles" />,
      selected: true,
      disabled: false,
      linkProps: {
        name: 'OrganizationSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="OrganizationSettingsNav.teamPermission" />,
      selected: false,
      disabled: false,
      linkProps: {
        name: 'TeamPermissionPage',
      },
    },
    {
      text: <FormattedMessage id="OrganizationSettingsNav.transactionHistory" />,
      selected: false,
      disabled: false,
      linkProps: {
        name: 'PaymentHistoryPage',
      },
    },
  ];

  const organizationSettingsNavigation = isSingleOrganizationView ? null : (
    <DashboardNav tabs={organizationSettingsTabs} selectedPageName="OrganizationSettingsPage" />
  );

  const organizationSettingsForm = (
    <OrganizationSettingsForm
      currentUser={currentUser}
      listings={listings}
      selectedOrganization={selectedOrganization}
      initialValues={{
        organizationProfiles,
      }}
      onSubmit={async values => {
        const organizationProfiles = await prepareUpdatedOrganizationProfiles(
          values.organizationProfiles,
          onUploadImage
        );

        onSaveOrganizationProfiles(organizationProfiles);
      }}
      saveOrganizationProfilesInProgress={saveOrganizationProfilesInProgress}
      saveOrganizationProfilesError={saveOrganizationProfilesError}
      uploadImageInProgress={uploadImageInProgress}
      isSingleOrganizationView={isSingleOrganizationView}
      activeOrganizationId={activeOrganizationId}
      createOrUpdateSuccessMessage={createOrUpdateSuccessMessage}
      setActiveOrganizationId={setActiveOrganizationId}
      setActionType={setActionType}
      toggleInvalidDeleteModal={toggleInvalidDeleteModal}
    />
  );

  const invalidDeleteModal = (
    <Modal
      id="OrganizationSettingsPage.invalidDelete"
      isOpen={isInvalidDeleteModalOpen}
      onClose={() => toggleInvalidDeleteModal(false)}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h1 className={css.modalTitle}>
        <FormattedMessage id="OrganizationSettingsPage.modalTitle" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="OrganizationSettingsPage.modalMessage" />
      </p>
    </Modal>
  );

  const successInviteModal = (
    <SuccessInviteModal
      id="OrganizationSettingsPage.successInviteModal"
      containerClassName={css.inviteModalContainer}
      contentClassName={css.inviteModalContent}
      isOpen={isSuccessInviteModalOpen}
      onCloseModal={() => {
        setSuccessInviteModalOpen(false);

        // clear location state
        history.replace();
      }}
      onManageDisableScrolling={onManageDisableScrolling}
    />
  );

  return (
    <Page className={css.root} scrollingDisabled={scrollingDisabled} title={schemaTitle}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="OrganizationSettingsPage"
            desktopClassName={css.topbar}
            contentClassName={css.topbarContent}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <DashboardWrapper currentPage="OrganizationSettingsPage">
            <div className={css.pageContent}>
              <RedirectBanner location={location} history={history} />
              <h2 className={css.pageTitle}>
                <FormattedMessage id={titleMsgId(actionType)} />
              </h2>
              {organizationSettingsNavigation}
              {organizationSettingsForm}
              {invalidDeleteModal}
              {successInviteModal}
            </div>
          </DashboardWrapper>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

OrganizationSettingsPageComponent.defaultProps = {
  currentUser: null,
  listings: [],
  selectedOrganizationProfileId: null,
  saveOrganizationProfilesInProgress: false,
  saveOrganizationProfilesError: null,
  uplaodImageInProgress: false,
  onUploadImage: null,
};

OrganizationSettingsPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  listings: arrayOf(propTypes.listing),
  saveOrganizationProfilesInProgress: bool.isRequired,
  saveOrganizationProfilesError: propTypes.error,
  organizationProfiles: arrayOf(propTypes.organization),
  selectedOrganizationProfileId: string,
  uplaodImageInProgress: bool.isRequired,
  onUploadImage: func.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    saveOrganizationProfilesInProgress,
    saveOrganizationProfilesError,
    listingIds,
  } = state.OrganizationSettingsPage;
  const { organizationProfiles } = state.organizations;
  const { uploadImageInProgress } = state.googleStorage;

  const listings = getListingsById(state, listingIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    saveOrganizationProfilesInProgress,
    saveOrganizationProfilesError,
    uploadImageInProgress,
    organizationProfiles,
    selectedOrganizationProfileId: getSelectedOrganizationProfileIdFromExtendedData(currentUser),
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveOrganizationProfiles: organizationProfiles =>
    dispatch(saveOrganizationProfiles(organizationProfiles)),
  onUploadImage: image => dispatch(uploadImage(image)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const OrganizationSettingsPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(OrganizationSettingsPageComponent);

export default OrganizationSettingsPage;
