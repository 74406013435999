import React from 'react';
import { array, string, object, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import { IconReviewStar, NamedLink, ResponsiveImage } from '../../components';
import { getAverageReview } from '../../containers/ReviewsPage/ReviewsPage.duck';
import config from '../../config';

import css from './HorizontalListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const ListingImage = props => {
  const { title, posterImage, renderSizes } = props;
  return (
    <div className={css.listingImage}>
      <ResponsiveImage
        rootClassName={css.rootForImage}
        alt={title}
        image={posterImage}
        variants={[
          'square-small',
          'square-small2x',
          'portrait-crop',
          'portrait-crop2x',
          'portrait-crop4x',
        ]}
        sizes={renderSizes}
      />
    </div>
  );
};

const ListingDetails = props => {
  const { isExperienceListing } = props;
  return (
    <div className={css.details}>
      <span className={classNames(css.detailItem, css.detailItemCapitalized)}>
        {isExperienceListing ? (
          <FormattedMessage id="HorizontalListingCard.liveRoom" />
        ) : (
          <FormattedMessage id="HorizontalListingCard.show" />
        )}
      </span>
    </div>
  );
};

const ReviewsSection = props => {
  const { reviewsAndRatings, averageReview } = props;
  return (
    <div className={css.reviews}>
      <IconReviewStar className={css.reviewsIcon} />
      <p className={css.reviewsText}>
        <FormattedMessage
          id="HorizontalListingCard.review"
          values={{
            reviewLength: reviewsAndRatings.length,
            averageReview: averageReview,
          }}
        />
      </p>
    </div>
  );
};

const DetailsWrapper = props => {
  const { credit, category, genre, organizationProfile, address } = props;
  return (
    <div className={css.detailsWrapper}>
      {credit?.attributes?.year ? (
        <div className={css.details}>
          <span className={css.detailItem}>
            <FormattedMessage
              id="HorizontalListingCard.creditYear"
              values={{ start: credit.attributes.year.start, end: credit.attributes.year.end }}
            />
          </span>
        </div>
      ) : (
        <>
          <div className={css.details}>
            {category ? <span className={css.detailItem}>{category.label}</span> : null}
            {genre && organizationProfile?.name ? (
              <span className={css.detailItem}>
                <FormattedMessage
                  id="HorizontalListingCard.genreAndOrganizationName"
                  values={{
                    genre: genre.label,
                    organizationName: (
                      <span className={css.organizationName}>{organizationProfile.name}</span>
                    ),
                  }}
                />
              </span>
            ) : null}
          </div>
          <div className={css.details}>
            <span className={css.detailItem}>
              <FormattedMessage
                id="HorizontalListingCard.address"
                values={{
                  address,
                }}
              />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const HorizontalListingCardComponent = props => {
  const { className, rootClassName, listing, credit, renderSizes, filtersConfig } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData, metadata } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage = currentListing.images?.[0] || null;
  const posterImage = listing?.images?.find(i => i.id.uuid === publicData?.posterId) ?? firstImage;

  const address = publicData.location?.address;
  const organizationProfile = publicData?.organizationProfile;

  const typeOptions = findOptionsForSelectFilter('type', filtersConfig);
  const type = typeOptions.find(t => t.key === publicData?.type);

  const categoryOptions = findOptionsForSelectFilter('category', filtersConfig);
  const category = categoryOptions.find(c => c.key === publicData?.category);

  const genreOptions = findOptionsForSelectFilter('genre', filtersConfig);
  const genre = genreOptions.find(s => s.key === publicData?.genre);

  const isExperienceListing = type.key === config.listingTypes['experience'];
  const linkPageName = isExperienceListing ? 'ExperiencePage' : 'ListingPage';

  const reviews = metadata?.reviews || [];
  const ratings = metadata?.showRatings || [];
  const reviewsAndRatings = [...reviews, ...ratings];
  const averageReview =
    reviewsAndRatings.length > 0 ? getAverageReview(currentListing, reviews) : 0;

  return (
    <NamedLink className={classes} name={linkPageName} params={{ id, slug }}>
      <ListingImage title={title} posterImage={posterImage} renderSizes={renderSizes} />
      <div className={css.info}>
        {credit ? (
          <ListingDetails credit={credit} isExperienceListing={isExperienceListing} />
        ) : (
          <ReviewsSection reviewsAndRatings={reviewsAndRatings} averageReview={averageReview} />
        )}
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        <DetailsWrapper
          credit={credit}
          category={category}
          genre={genre}
          organizationProfile={organizationProfile}
          address={address}
        />
      </div>
    </NamedLink>
  );
};

HorizontalListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  credit: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
};

HorizontalListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  credit: object,
  renderSizes: string,
  setActiveListing: func,
};

export default injectIntl(HorizontalListingCardComponent);
