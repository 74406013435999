import React from 'react';
import { func, number, object, string, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { ValidationError } from '..';

// External
import Select from 'react-select';
import classNames from 'classnames';

import css from './FieldMultiSelectInput.module.css';

const MULTI_SELECT = true;
const MENU_PLACEMENT = 'bottom';

/**
 * Handle input change
 */
const handleChange = (propsOnChange, inputOnChange, isMulti) => selectedOption => {
  const value = isMulti ? selectedOption.map(o => o.value || o) : selectedOption.value;

  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (propsOnChange) {
    propsOnChange(value);
  }
  // Notify Final Form that the input has changed.
  inputOnChange(value);
};

/**
 * Handle input disabled
 */
const handleOptionDisabled = (selectedOptions, maxOptions, isMultiOption) =>
  isMultiOption ? selectedOptions.length >= maxOptions : !!isMultiOption;

/** Denormalised select options in a format that is
 * support by react-select package.
 *
 * @param {array} options
 *
 * @return {array}
 */
const denormalisedOptions = options =>
  options.map(option => ({
    value: option.key,
    label: option.label,
  }));

/**
 * Formats input value in a format that is supported
 * by react-select package.
 *
 * @param {array} value
 * @param {array} options
 *
 * @return {array}
 */
const formatInputValue = (value, options, isMulti) => {
  if (value === '' || !value) {
    return '';
  }

  return isMulti
    ? value.map(v => ({
        value: v,
        label: options.find(o => o.key === v)?.label,
      }))
    : {
        value: value,
        label: options.find(o => o.key === value)?.label,
      };
};

const FieldMultiSelectInputComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    options,
    maxOptions,
    menuPlacement,
    disabled,
    isMulti,
    onChange,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const classes = classNames(rootClassName || css.root, className, {
    [css.selectError]: hasError,
  });

  // React Final Form input props
  const { value, onChange: inputOnChange, ...restOfInput } = input;

  // isMulti is set to TRUE by default, but we want to override it
  // if it's specified in the props.
  const isMultiOption = isMulti !== undefined ? isMulti : MULTI_SELECT;

  const selectProps = {
    id,
    name: id,
    options: denormalisedOptions(options),
    value: formatInputValue(value, options, isMultiOption),
    onChange: handleChange(onChange, inputOnChange, isMultiOption),
    isOptionDisabled: () =>
      handleOptionDisabled(input.value, maxOptions || options.length, isMultiOption),
    closeMenuOnSelect: !isMultiOption,
    menuPlacement: menuPlacement || MENU_PLACEMENT,
    isMulti: isMultiOption,
    isDisabled: disabled,
    ...restOfInput,
    ...rest,
  };

  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Select {...selectProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldMultiSelectInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  disabled: false,
};

FieldMultiSelectInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,
  menuPlacement: string,
  maxOptions: number,
  disabled: bool.isRequired,
  onChange: func,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
};

const FieldMultiSelectInput = props => {
  return <Field component={FieldMultiSelectInputComponent} {...props} />;
};

export default FieldMultiSelectInput;
