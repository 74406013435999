import { integrationAPI } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  findTeamMemberById,
  updateCreditsForPublicData,
  updateTeamForPublicData,
} from './VerifyListingInvitePage.shared';

// ================ Action types ================ //

export const VERIFY_INVITE_REQUEST = 'app/VerifyListingInvitePage/VERIFY_INVITE_REQUEST';
export const VERIFY_INVITE_SUCCESS = 'app/VerifyListingInvitePage/VERIFY_INVITE_SUCCESS';
export const VERIFY_INVITE_ERROR = 'app/VerifyListingInvitePage/VERIFY_INVITE_ERROR';

export const VERIFICATION_INVALID_OR_EXPIRED_ERROR =
  'app/VerifyListingInvitePage/VERIFICATION_INVALID_OR_EXPIRED_ERROR';
export const INVALID_PARAMS_ERROR = 'app/VerifyListingInvitePage/INVALID_PARAMS_ERROR';

// ================ Reducer ================ //

const initialState = {
  verifyInviteInProgress: false,
  verifyInviteError: null,
  verificationInvalidOrExpiredError: null,
  invalidParamsError: null,
  isVerified: false,
};

export default function verifyListingInvitePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case VERIFY_INVITE_REQUEST:
      return {
        ...state,
        verifyInviteInProgress: true,
        verifyInviteError: null,
        isVerified: false,
      };
    case VERIFY_INVITE_SUCCESS:
      return {
        ...state,
        verifyInviteInProgress: false,
        verifyInviteError: null,
        isVerified: true,
      };
    case VERIFY_INVITE_ERROR:
      return {
        ...state,
        verifyInviteInProgress: false,
        verifyInviteError: payload,
        isVerified: false,
      };

    case VERIFICATION_INVALID_OR_EXPIRED_ERROR:
      return {
        ...state,
        verificationInvalidOrExpiredError: payload,
      };
    case INVALID_PARAMS_ERROR:
      return {
        ...state,
        invalidParamsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const verifyInviteRequest = () => ({
  type: VERIFY_INVITE_REQUEST,
});
export const verifyInviteSuccess = () => ({
  type: VERIFY_INVITE_SUCCESS,
});
export const verifyInviteError = e => ({
  type: VERIFY_INVITE_ERROR,
  error: true,
  payload: e,
});

export const verificationInvalidOrExpiredError = e => ({
  type: VERIFICATION_INVALID_OR_EXPIRED_ERROR,
  error: true,
  payload: e,
});
export const invalidParamsError = e => ({
  type: INVALID_PARAMS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

/**
 * Requests an update to a pending invite.
 */
export const requestUpdatePendingInviteForListing = (team, urlParams) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const { listingId, recipientId } = urlParams;
    const { currentUser } = getState().user;

    const teamForPublicData = updateTeamForPublicData(team, recipientId, currentUser.id.uuid);

    await integrationAPI.listings.update({
      id: listingId,
      publicData: {
        team: teamForPublicData,
      },
    });

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const requestUpdatePendingInviteForRecipient = urlParams => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const { listingId, recipientId } = urlParams;

    const { currentUser } = getState().user; // currentUser is the same as recipient
    const credits = currentUser?.attributes?.profile?.publicData?.credits;

    const creditsForPublicData = updateCreditsForPublicData(credits, listingId, recipientId);

    await sdk.currentUser.updateProfile({
      publicData: {
        credits: creditsForPublicData,
      },
    });

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

const validateInvalidOrExpiredVerification = (team, urlParams) => async (
  dispatch,
  getState,
  sdk
) => {
  const { recipientId } = urlParams;
  let { currentUser } = getState().user;

  if (!currentUser) {
    const currentUserResponse = await sdk.currentUser.show();
    currentUser = currentUserResponse.data.data;
  }

  // Check if current pending invite exists
  const invite = findTeamMemberById(team, recipientId);
  const hasInvite = !!invite;
  const isValidPendingInvite = invite?.pending;

  // Check if currentUser id is the same as recipientId
  const isSameUser = invite?.email === currentUser?.attributes?.email;

  if (!hasInvite || !isValidPendingInvite || !isSameUser) {
    const errorMessage = 'Verification invalid or expired.';
    console.error(errorMessage);

    // Throw error
    dispatch(verificationInvalidOrExpiredError({ message: errorMessage }));
    throw new Error(errorMessage);
  }
};

/**
 * Throw error if params are invalid.
 */
const validateVerificationParams = urlParams => dispatch => {
  const { senderId, recipientId, listingId, listingType, listingState } = urlParams || {};

  if (!senderId || !recipientId || !listingId || !listingType || !listingState) {
    const errorMessage = 'Invalid params error';
    console.error(errorMessage, urlParams);

    // Throw error
    dispatch(invalidParamsError({ message: errorMessage }));
    throw new Error(errorMessage);
  }
};

/**
 * Verifies an invite by updating listing data.
 */
export const verifyInvite = urlParams => async (dispatch, getState, sdk) => {
  try {
    dispatch(validateVerificationParams(urlParams));
    dispatch(verifyInviteRequest());

    const { listingId } = urlParams;

    const response = await integrationAPI.listings.show({ id: listingId });
    const listing = response.data.data.data;

    // Get the pending invites from the sender's public data.
    const teamFromPublicData = listing.attributes.publicData.team;

    // Validate expired verifications
    await dispatch(validateInvalidOrExpiredVerification(teamFromPublicData, urlParams));

    // Update listing pending invite
    await dispatch(requestUpdatePendingInviteForListing(teamFromPublicData, urlParams));

    // Update recipient pending invite
    await dispatch(requestUpdatePendingInviteForRecipient(urlParams));

    // Dispatch an action to indicate the verification process is successful.
    return dispatch(verifyInviteSuccess());
  } catch (error) {
    console.error(error, 'my error');
    dispatch(verifyInviteError(storableError(error)));
    throw error; // Re-throw the error to ensure it propagates up the promise chain.
  }
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const urlParams = parse(search);
  return dispatch(verifyInvite(urlParams));
};
