import React from 'react';
import PropTypes, { arrayOf, object } from 'prop-types';
import { propTypes } from '../../util/types';
import { VideoPlayer, VideoFromFile, IconSpinner } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import ControlMenu from './ControlMenu';
import css from './AddVideos.module.css';

const MENU_CONTENT_OFFSET = 12;

// VideoPlayer settings
const VIDEO_PLAYER_SETTINGS = {
  playerTime: false,
};

const ThumbnailWrapper = props => {
  const {
    video,
    listing,
    removeVideoText,
    onRemoveVideo,
    // Cover props
    onSetCover,
    setCoverInProgress,
    setCoverError,
  } = props;

  const { publicData } = listing.attributes;
  const coverVideo =
    typeof video.id !== 'undefined' && publicData.coverId && publicData.coverId === video.id;

  const handleRemoveClick = e => {
    e.stopPropagation();

    if (window.confirm(removeVideoText)) {
      onRemoveVideo(video.id);
    }
  };
  const handleCoverClick = e => {
    e.stopPropagation();
    const videoId = coverVideo ? null : video.id;
    onSetCover(listing.id, videoId);
  };

  const coverLabel = coverVideo ? (
    <span className={css.cover}>
      <FormattedMessage id="AddVideos.coverVideo" />
    </span>
  ) : null;

  const controlMenu = !video.uploading ? (
    <ControlMenu
      contentPlacementOffset={MENU_CONTENT_OFFSET}
      onRemoveVideo={handleRemoveClick}
      onSetCover={handleCoverClick}
      setCoverInProgress={setCoverInProgress}
      setCoverError={setCoverError}
      coverVideo={coverVideo}
      showCoverOption={!!onSetCover}
    />
  ) : null;

  if (video.file) {
    // While video is uploading we show overlay on top of thumbnail
    const uploadingOverlay = video.uploading ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;
    return (
      <VideoFromFile id={video.id} file={video.file}>
        {controlMenu}
        {coverLabel}
        {uploadingOverlay}
      </VideoFromFile>
    );
  } else {
    const showVideo = video.src;
    return (
      <div className={css.thumbnail}>
        {controlMenu}
        {coverLabel}
        {showVideo ? (
          <VideoPlayer
            className={css.videoPlayer}
            videoWrapperClassName={css.videoPlayerAspectWrapper}
            videoClassName={css.video}
            video={video}
            settings={{
              ...VIDEO_PLAYER_SETTINGS,
            }}
          />
        ) : null}
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = {
  videos: [],
  listing: null,
  onRemoveVideo: null,
};

const { string, node, func } = PropTypes;

ThumbnailWrapper.propTypes = {
  videos: arrayOf(propTypes.video).isRequired,
  listing: object.isRequired,
  onRemoveVideo: func.isRequired,
};

const AddVideos = props => {
  const { children, rootClassName, className, videos, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {videos.map(video => {
        return <ThumbnailWrapper key={video.id} id={video.id} video={video} {...rest} />;
      })}
      {children}
    </div>
  );
};

AddVideos.defaultProps = {
  rootClassName: null,
  className: null,
  videos: [],
};

AddVideos.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
  videos: arrayOf(propTypes.video).isRequired,
};

export default AddVideos;
