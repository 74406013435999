import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, DASHBOARD_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink } from '../../components';
import config from '../../config';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const { isAuthenticated, currentPage, currentUser, onLogout } = props;

  const user = ensureCurrentUser(currentUser);
  const isCurrentUserArtist =
    currentUser?.attributes?.profile?.publicData?.role === config.userRoles['artist'];
  const isDashboardPage = DASHBOARD_PAGES.includes(currentPage);

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const renderLinks = () => {
    const aboutPageLink = !isDashboardPage && (
      <NamedLink
        name="AboutPage"
        className={classNames(css.navigationLink, currentPageClass('AboutPage'))}
      >
        <FormattedMessage id="TopbarDesktop.about" />
      </NamedLink>
    );

    const startInvolvingLink = !isDashboardPage && (
      <NamedLink
        name="AudiencePage"
        className={classNames(css.navigationLink, currentPageClass('AudiencePage'))}
      >
        <FormattedMessage id="TopbarDesktop.startInvolving" />
      </NamedLink>
    );

    const startAttractingLink = !isDashboardPage && (
      <NamedLink
        name="CreatorsPage"
        className={classNames(css.navigationLink, currentPageClass('CreatorsPage'))}
      >
        <FormattedMessage id="TopbarDesktop.startAttracting" />
      </NamedLink>
    );

    const helpCenterLink = (
      <NamedLink
        name="HelpPage"
        className={classNames(css.navigationLink, currentPageClass('HelpPage'))}
      >
        <FormattedMessage id="TopbarDesktop.helpLink" />
      </NamedLink>
    );

    const communityLink = (
      <NamedLink
        name="CommunityPage"
        className={classNames(css.navigationLink, currentPageClass('CommunityPage'))}
      >
        <FormattedMessage id="TopbarDesktop.communityLink" />
      </NamedLink>
    );

    const subscriptionsLink = isDashboardPage ? (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          css.firstNavigationLink,
          currentPageClass('ManageListingsPage')
        )}
        name="ManageListingsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.manageListingsLink" />
      </NamedLink>
    ) : (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          css.firstNavigationLink,
          currentPageClass('ManageSubscriptionsPage')
        )}
        name="ManageSubscriptionsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.subscriptionsLink" />
      </NamedLink>
    );

    const watchlistLink = isDashboardPage ? null : (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          currentPageClass('WatchlistPage')
        )}
        name="WatchlistPage"
      >
        <FormattedMessage id="TopbarMobileMenu.watchlistLink" />
      </NamedLink>
    );

    const calendarLink = isDashboardPage ? (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          currentPageClass('StreamPage')
        )}
        name="StreamPage"
      >
        <FormattedMessage id="TopbarMobileMenu.streamLink" />
      </NamedLink>
    ) : (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          currentPageClass('CalendarPage')
        )}
        name="CalendarPage"
      >
        <FormattedMessage id="TopbarMobileMenu.calendarLink" />
      </NamedLink>
    );

    const inboxLink = isDashboardPage ? (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          currentPageClass('InboxPage')
        )}
        name="InboxPage"
      >
        <FormattedMessage id="TopbarMobileMenu.inboxLink" />
      </NamedLink>
    ) : (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          currentPageClass('OrdersPage')
        )}
        name="OrdersPage"
      >
        <FormattedMessage id="TopbarMobileMenu.inboxLink" />
      </NamedLink>
    );

    const ordersLink = isDashboardPage ? (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          currentPageClass('OrganizationSettingsPage')
        )}
        name="OrganizationSettingsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.organizationSettinsLink" />
      </NamedLink>
    ) : (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          currentPageClass('OrderHistoryPage')
        )}
        name="OrderHistoryPage"
      >
        <FormattedMessage id="TopbarMobileMenu.ordersLink" />
      </NamedLink>
    );

    const analyticsLink = isDashboardPage && (
      <NamedLink
        className={classNames(
          css.navigationLink,
          css.navigationMainLink,
          currentPageClass('AnalyticsPage')
        )}
        name="AnalyticsPage"
      >
        <FormattedMessage id="TopbarDesktop.analyticsLink" />
      </NamedLink>
    );

    const accountSettingsLink = !isDashboardPage && (
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
        name="AccountSettingsPage"
      >
        <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
      </NamedLink>
    );

    return (
      <>
        {subscriptionsLink}
        {watchlistLink}
        {calendarLink}
        {inboxLink}
        {analyticsLink}
        {ordersLink}
        {aboutPageLink}
        {startInvolvingLink}
        {startAttractingLink}
        {accountSettingsLink}
        {helpCenterLink}
        {communityLink}
      </>
    );
  };

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="RoleSelectionPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink} params={{ role: 'audience' }}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const displayName = user.attributes.profile.firstName;

  return (
    <div
      className={classNames(css.root, {
        [css.dashboardRoot]: isDashboardPage,
      })}
    >
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {renderLinks()}
      </div>

      {isDashboardPage ? (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="LandingPage">
            <FormattedMessage id="TopbarDesktop.switchToAudienceLink" />
          </NamedLink>
        </div>
      ) : isCurrentUserArtist ? (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="ManageListingsPage">
            <FormattedMessage id="TopbarDesktop.businessLink" />
          </NamedLink>
        </div>
      ) : null}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  isAuthenticated: false,
  currentPage: null,
  currentUser: null,
  onLogout: null,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentPage: string,
  currentUser: propTypes.currentUser,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
