import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';
import { integrationAPI } from '../../util/api';
import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { getSelectedOrganizationProfileIdFromExtendedData } from '../../util/organizations';

// ================ Action types ================ //

export const SAVE_ORGANIZATION_PROFILES_REQUEST =
  'app/OrganizationSettingsPage/SAVE_ORGANIZATION_PROFILES_REQUEST';
export const SAVE_ORGANIZATION_PROFILES_SUCCESS =
  'app/OrganizationSettingsPage/SAVE_ORGANIZATION_PROFILES_SUCCESS';
export const SAVE_ORGANIZATION_PROFILES_ERROR =
  'app/OrganizationSettingsPage/SAVE_ORGANIZATION_PROFILES_ERROR';

export const FETCH_LISTINGS_REQUEST = 'app/OrganizationSettingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/OrganizationSettingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/OrganizationSettingsPage/FETCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveOrganizationProfilesInProgress: false,
  saveOrganizationProfilesError: null,
  fetchListingsInProgress: false,
  fetchListingsError: null,
  fetchInvitedOrganizationsInProgress: false,
  fetchInvitedOrganizationsError: null,
  listingIds: [],
};

const resultIds = listings => listings.map(l => l.id);

export default function organizationSettingsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_ORGANIZATION_PROFILES_REQUEST:
      return {
        ...state,
        saveOrganizationProfilesInProgress: true,
        saveOrganizationProfilesError: null,
      };
    case SAVE_ORGANIZATION_PROFILES_SUCCESS:
      return {
        ...state,
        saveOrganizationProfilesInProgress: false,
        saveOrganizationProfilesError: null,
      };
    case SAVE_ORGANIZATION_PROFILES_ERROR:
      return {
        ...state,
        saveOrganizationProfilesInProgress: false,
        saveOrganizationProfilesError: payload,
      };

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsError: null,
        listingIds: resultIds(payload.data),
      };
    case FETCH_LISTINGS_ERROR:
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsError: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveOrganizationProfilesRequest = () => ({
  type: SAVE_ORGANIZATION_PROFILES_REQUEST,
});
export const saveOrganizationProfilesSuccess = () => ({
  type: SAVE_ORGANIZATION_PROFILES_SUCCESS,
});
export const saveOrganizationProfilesError = e => ({
  type: SAVE_ORGANIZATION_PROFILES_ERROR,
  error: true,
  payload: e,
});

export const fetchInvitedOrganizationsRequest = () => ({
  type: FETCH_INVITED_ORGANIZATIONS_REQUEST,
});
export const fetchInvitedOrganizationsSuccess = organizationProfiles => ({
  type: FETCH_INVITED_ORGANIZATIONS_SUCCESS,
  payload: { organizationProfiles },
});
export const fetchInvitedOrganizationsError = e => ({
  type: FETCH_INVITED_ORGANIZATIONS_ERROR,
  payload: e,
});

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});
export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data.data },
});
export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const saveOrganizationProfiles = organizationProfiles => async (dispatch, getState, sdk) => {
  dispatch(saveOrganizationProfilesRequest());

  const queryParams = {
    expand: true,
    include: ['profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  const ownOrganizationProfiles = organizationProfiles.filter(profile => !profile.invited);
  const invitedOrganizationProfiles = organizationProfiles.filter(profile => profile.invited);

  try {
    const response = await sdk.currentUser.updateProfile(
      {
        publicData: {
          organizationProfiles: ownOrganizationProfiles,
        },
      },
      queryParams
    );

    if (invitedOrganizationProfiles.length > 0) {
      const updateInvitedOrganizations = invitedOrganizationProfiles.map(async profile => {
        const { creatorId, invited, ...updateValues } = profile;

        const creatorUser = await integrationAPI.users.show({ id: creatorId });
        const creatorOrganizations = creatorUser?.data?.data?.data?.attributes?.profile?.publicData?.organizationProfiles?.filter(
          org => org.id !== profile.id
        );

        const updateCreatorOrganizations = await integrationAPI.users.updateProfile({
          id: creatorId,
          publicData: {
            organizationProfiles:
              creatorOrganizations?.length > 0
                ? [...creatorOrganizations, updateValues]
                : [updateValues],
          },
        });

        return updateCreatorOrganizations;
      });

      await Promise.all(updateInvitedOrganizations);
    }

    dispatch(addMarketplaceEntities(response));
    dispatch(saveOrganizationProfilesSuccess());

    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
    }
    const currentUser = entities[0];

    // Update current user in state.user.currentUser through user.duck.js
    dispatch(currentUserShowSuccess(currentUser));
    dispatch(fetchOrganizationProfiles());

    return response;
  } catch (error) {
    // Handle error here
    console.error('Error saving organization profiles:', error);
    dispatch(saveOrganizationProfilesError(storableError(error)));
    // Re-throw the error to propagate it
    throw error;
  }
};

export const fetchListings = organizationProfileId => async (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());

  return sdk.listings
    .query({
      organizationId: organizationProfileId,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(fetchListingsError(storableError(e))));
};

export const loadData = () => async (dispatch, getState, sdk) => {
  const currentUser = await dispatch(fetchCurrentUser());
  const selectedOrganizationProfileId = getSelectedOrganizationProfileIdFromExtendedData(
    currentUser
  );

  return Promise.all([
    dispatch(fetchOrganizationProfiles()),
    dispatch(fetchListings(selectedOrganizationProfileId)),
  ]);
};
