import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { IconArrow } from '../../components';

import css from './OrganizationSettingsPage.module.css';

const RedirectBanner = props => {
  const { history, location } = props;

  const locationFrom = location?.state?.from;
  const showName = location?.state?.showName;
  const showRedirectBanner = locationFrom;

  return showRedirectBanner ? (
    <div className={css.redirectBanner} onClick={() => history.push(locationFrom)}>
      <IconArrow />
      {showName ? (
        <FormattedMessage id="OrganizationSettingsPage.redirect" values={{ showName }} />
      ) : (
        <FormattedMessage id="OrganizationSettingsPage.redirectWithoutName" />
      )}
    </div>
  ) : null;
};

export default RedirectBanner;
