import React from 'react';
import { array, string, number } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing, getFirstStringPart } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import { IconReviewStar, NamedLink, ButtonWatchlist } from '../../components';
import { getAverageReview } from '../../containers/ReviewsPage/ReviewsPage.duck';
import config from '../../config';

import increaseImage from './images/increase-img.svg';
import css from './HeroListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const HeroListingCardComponent = props => {
  const { className, rootClassName, listing, listingIndex, filtersConfig } = props;
  const classes = classNames(rootClassName || css.root, className);

  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData, metadata } = currentListing.attributes;
  const slug = createSlug(title);

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const coverImage = listing?.images?.find(i => i.id.uuid === publicData?.coverId) ?? firstImage;
  const coverImageUrl = coverImage?.attributes?.variants['scaled-large']?.url;

  const categoryOptions = findOptionsForSelectFilter('category', filtersConfig);
  const category = categoryOptions.find(c => c.key === publicData?.category);

  const statusOptions = findOptionsForSelectFilter('status', filtersConfig);
  const status = statusOptions.find(s => s.key === publicData?.status);

  const organizationProfile = publicData?.organizationProfile;
  const address = publicData?.location?.address;
  const normalizedAddress = getFirstStringPart(address);

  const reviews = metadata?.reviews ? metadata.reviews : [];
  const ratings = metadata?.showRatings ? metadata.ratings : [];
  const reviewsAndRatings = reviews.concat(ratings);
  const hasReviewsOrRatings = reviewsAndRatings.length > 0;
  const averageReview = hasReviewsOrRatings ? getAverageReview(currentListing, reviews) : 0;

  return (
    <div
      className={classes}
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 100)),url(${coverImageUrl})`,
      }}
    >
      <ButtonWatchlist className={css.watchlistButton} listing={currentListing} />

      <div className={css.info}>
        {organizationProfile ? <h3 className={css.subTitle}>{organizationProfile.name}</h3> : null}
        <h1 className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </h1>
        <div className={css.details}>
          {category ? <span className={css.detail}>{category.label}</span> : null}
          {status ? <span className={css.detail}>{status.label}</span> : null}
          {address ? (
            <span className={css.detail}>
              <FormattedMessage
                id="HeroListingCard.address"
                values={{ address: normalizedAddress }}
              />
            </span>
          ) : null}
        </div>
        <div className={css.extraInfo}>
          <div className={css.reviews}>
            <IconReviewStar className={css.reviewsIcon} />
            <p className={css.reviewsText}>
              <FormattedMessage
                id="HeroListingCard.review"
                values={{
                  reviewLength: reviewsAndRatings.length,
                  averageReview: averageReview,
                }}
              />
            </p>
          </div>
          <p className={css.extraInfoText}>
            <img className={css.extraInfoImg} src={increaseImage} alt="Increase" />
            <FormattedMessage id="HeroListingCard.trending" />
          </p>
        </div>
        <div className={css.listingLinkWrapper}>
          <NamedLink className={css.listingLink} name="ListingPage" params={{ id, slug }}>
            <FormattedMessage id="HeroListingCard.listingLink" />
          </NamedLink>
          {listingIndex ? <span className={css.listingOrder}>{listingIndex}</span> : null}
        </div>
      </div>
    </div>
  );
};

HeroListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  listingIndex: null,
  filtersConfig: config.custom.filters,
};

HeroListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listingIndex: number,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,
};

const HeroListingCard = compose(
  withRouter,

  injectIntl
)(HeroListingCardComponent);

export default HeroListingCard;
