import { fetchOrganizationProfiles } from '../../ducks/organizations.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { integrationAPI } from '../../util/api';
import { storableError } from '../../util/errors';
import {
  getSelectedOrganizationProfile,
  getSelectedOrganizationProfileIdFromExtendedData,
} from '../../util/organizations';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 9;

const ALL_LISTINGS_KEY = 'all-listings';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  entities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
};

const resultIds = data => data.data.map(l => l.id);

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      const listingIds = resultIds(payload.data);
      return {
        ...state,
        currentPageResultIds: listingIds,
        pagination: {
          ...payload.data.meta,
          totalItems: listingIds.length,
        },
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...state,
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...state,
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

// ================ Action creators ================ //

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: { data: response.data },
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = (response, isOwn) => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: { data: response.data, isOwn },
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => {
  return {
    type: FETCH_LISTINGS_SUCCESS,
    payload: { data: response.data },
  };
};

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryListings = queryParams => async (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, pub_type, ...rest } = queryParams;
  const typeMaybe = pub_type
    ? pub_type === ALL_LISTINGS_KEY
      ? {}
      : {
          pub_type,
        }
    : {};
  const params = { ...rest, ...typeMaybe, perPage: perPage };

  return integrationAPI.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response.data));
      dispatch(queryListingsSuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return integrationAPI.listings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return integrationAPI.listings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const currentUser = await dispatch(fetchCurrentUser());
  const selectedOrganizationProfileId = getSelectedOrganizationProfileIdFromExtendedData(
    currentUser
  );

  const organizationProfiles = await dispatch(fetchOrganizationProfiles());
  const selectedOrganization = getSelectedOrganizationProfile(
    organizationProfiles,
    selectedOrganizationProfileId
  );

  const fetchParams = {
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    pub_organizationId: selectedOrganization.id,
    include: ['images', 'author'],
    'fields.image': [
      'variants.square-small',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
    'limit.images': 1,
  };

  // Query listings with marketplace sdk
  return dispatch(queryListings(fetchParams));
};
