import React, { Component } from 'react';
import { array, bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration, { DASHBOARD_PAGES } from '../../routeConfiguration';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { pickSearchParamsOnly } from '../../containers/SearchPage/SearchPage.helpers';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
  TopbarSearchFilterModal as TopbarSearchModal,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import MenuIcon from './MenuIcon';
import TopbarNotice from './TopbarNotice';
import css from './Topbar.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => k !== modalStateParam);
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const formatArrayValue = selectedOptions => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  return hasOptionsSelected ? `has_any:${selectedOptions.join(',')}` : null;
};

const GenericError = ({ show }) => {
  const classes = classNames(css.genericError, { [css.genericErrorVisible]: show });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTopbarSearchModalOpen: false,
      isTopbarSearchFocused: false,
      keywords: '',
    };

    this.topbarSearchRef = React.createRef();
    this.topbarSearchModalContentRef = React.createRef();

    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.handleTopbarSearchModalOpen = this.handleTopbarSearchModalOpen.bind(this);
    this.handleTopbarSearchModalClose = this.handleTopbarSearchModalClose.bind(this);
    this.handleTopbarSearchFocus = this.handleTopbarSearchFocus.bind(this);
    this.handleTopbarSearchSubmit = this.handleTopbarSearchSubmit.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.onKeywordChange = this.onKeywordChange.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleTopbarSearchModalOpen() {
    this.setState({ isTopbarSearchModalOpen: true });
  }

  handleTopbarSearchModalClose() {
    this.setState({ isTopbarSearchFocused: false, isTopbarSearchModalOpen: false });
  }

  handleSubmit(values) {
    const { currentSearchParams, history } = this.props;
    const searchParams = {
      ...currentSearchParams,
      ...this.getSearchParams(values),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());
      window.location = path;
    });
  }

  handleTopbarSearchFocus() {
    this.setState({ isTopbarSearchFocused: true });
    this.handleTopbarSearchModalOpen();
  }

  handleTopbarSearchSubmit(values) {
    const { history } = this.props;
    const searchParams = this.getSearchParams(values);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  initialValues(queryParamNames) {
    let initialValues = {};
    for (const [key, value] of Object.entries(queryParamNames)) {
      const searchMode = value.includes('has_any:') ? 'has_any:' : null;
      const paramValue = searchMode ? value.replace(searchMode, '').split(',') : value;
      initialValues = { ...initialValues, [key]: paramValue };
    }
    return initialValues;
  }

  onKeywordChange(e) {
    this.setState({ keywords: e.target.value });
  }

  getSearchParams(values) {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords: values?.keywords };
    }
    const { search, selectedPlace } = values?.location;
    const { origin, bounds } = selectedPlace;
    const originMaybe = isOriginInUse(config) ? { origin } : {};
    return { ...originMaybe, address: search, bounds };
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      contentClassName,
      logoClassName,
      isAuthenticated,
      authScopes,
      currentUser,
      currentUserHasListings,
      currentUserListing,
      currentUserListingFetched,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      showGenericError,
      filterConfig,
      sortConfig,
      logoFormat,
    } = this.props;

    const { mobilemenu, mobilesearch, keywords, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';
    const isDashboardPage = DASHBOARD_PAGES.includes(currentPage);

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUserListing={currentUserListing}
        currentUserListingFetched={currentUserListingFetched}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
      />
    );

    const topbarSearchInitialValues = () =>
      isMainSearchTypeKeywords(config) ? { keywords } : null;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <TopbarNotice currentPage={currentPage} />
        <div
          className={classNames(mobileRootClassName || css.container, mobileClassName, {
            [css.mobileRootDashboard]: isDashboardPage,
          })}
        >
          <NamedLink
            className={css.home}
            name={isDashboardPage ? 'ManageListingsPage' : 'LandingPage'}
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
          >
            <Logo format="mobile" />
          </NamedLink>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            contentClassName={contentClassName}
            logoClassName={logoClassName}
            currentUserHasListings={currentUserHasListings}
            currentUserListing={currentUserListing}
            currentUserListingFetched={currentUserListingFetched}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={topbarSearchInitialValues()}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            logoFormat={logoFormat}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            onKeywordChange={this.onKeywordChange}
            keywords={this.state.keywords}
            topbarSearchRef={this.topbarSearchRef}
            isTopbarSearchFocused={this.state.isTopbarSearchFocused}
            handleTopbarSearchFocus={this.handleTopbarSearchFocus}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={topbarSearchInitialValues()}
              isMobile
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>

        <TopbarSearchModal
          id="TopbarSearchModal"
          isOpen={this.state.isTopbarSearchModalOpen}
          onCloseModal={this.handleTopbarSearchModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmit={this.handleTopbarSearchSubmit}
          initialValues={this.initialValues}
          urlQueryParams={pickSearchParamsOnly(searchInURL, filterConfig, sortConfig)}
          topbarFilters={filterConfig.filter(f => f.showInTopbar)}
          topbarSearchModalContentRef={this.topbarSearchModalContentRef}
        />
        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  contentClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  logoFormat: null,
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  contentClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  showGenericError: bool.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  logoFormat: string,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
