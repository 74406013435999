import React, { useState } from 'react';
import { bool, func, object, string, array } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { getDefaultTimeZoneOnBrowser, isDate, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { InlineTextButton, EditWizardButton, ListingLink, Modal, PreviewListingButton } from '..';
import { EditExperienceAvailabilityForm } from '../../forms';
import config from '../../config';
import moment from 'moment';

import AvailabilityExceptions from './AvailabilityExceptions';
import css from './EditExperienceAvailabilityPanel.module.css';

const DEFAULT_AVAILABILITY_SEATS = 100;

const validateDate = date => (isDate(date) ? date : new Date(date));

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return validateDate(a.attributes.start).getTime() - validateDate(b.attributes.start).getTime();
};

// Denormalise and format availabilityException repating dates
// that we get from the form values.
const denormaliseRepeatingDates = repeatingDates =>
  repeatingDates?.filter(d => d?.date?.date)?.map(d => d.date.date) || [];

//////////////////////////////////
// EditExperienceAvailabilityPanel //
//////////////////////////////////
const EditExperienceAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onAddRepeatingAvailabilityExceptions,
    addExceptionInProgress,
    addRepeatingExceptionsInProgress,
    deleteExceptionId,
    deleteExceptionInProgress,
    disabled,
    onSubmit,
    onUpdateListing,
    onManageDisableScrolling,
    onRedirectToPreviousTab,
    onCreateMeetingRoom,
    submitButtonText,
    updateInProgress,
    errors,
    durationConfigOptions,
  } = props;

  // Hooks
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const exceptionButtonDisabled = disabled || fetchExceptionsInProgress;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);
  const publicDataAvailabilityExceptions = publicData?.exceptions;

  // Save exception metadata
  const saveExceptionMetadata = async (listingId, exceptionId, values, repeatingExceptions) => {
    const {
      exceptionStartDate,
      exceptionEndDate,
      exceptionStartTime,
      exceptionEndTime,
      repeatingDates,
      ...rest
    } = values;

    const meetingRoom = await onCreateMeetingRoom({
      roomPrefixName: rest.title,
      endDate: exceptionEndDate.date,
    });
    const {
      startDate: mainExceptionMeetingStartDate,
      endDate: mainExceptionMeetingEndDate,
      ...restMeetingInfo
    } = meetingRoom;

    // additional information that we call exception metadata
    const exception = {
      id: exceptionId,
      type: 'exception',
      attributes: {
        start: exceptionStartTime,
        end: exceptionEndTime,
        createdAt: Date.now(),
        meeting: {
          ...restMeetingInfo,
        },
        ...rest,
      },
    };

    const repeatingExceptionsMaybe = repeatingExceptions
      ? await Promise.all(
          repeatingExceptions.map(async e => {
            const repeatingMeetingRoom = await onCreateMeetingRoom({
              roomPrefixName: rest.title,
              endDate: e.attributes.end,
            });
            const {
              startDate: repeatingExceptionMeetingStartDate,
              endDate: repeatingExceptionMeetingEndDate,
              ...restRepeatingMeetingInfo
            } = repeatingMeetingRoom;
            return {
              id: e.id.uuid,
              type: 'repeating-exception',
              attributes: {
                start: new Date(e.attributes.start).valueOf(),
                end: new Date(e.attributes.end).valueOf(),
                meeting: {
                  ...restRepeatingMeetingInfo,
                },
                ...rest,

                // Additional attributes specific to repeating
                // availability exceptions.
                mainExceptionId: exception.id,
              },
            };
          })
        )
      : [];

    // Add exceptions to listing publicData
    const updatedExceptions = publicDataAvailabilityExceptions
      ? [exception, ...repeatingExceptionsMaybe, ...publicDataAvailabilityExceptions]
      : [exception, ...repeatingExceptionsMaybe];
    return onUpdateListing('availability', {
      id: listingId,
      publicData: {
        exceptions: updatedExceptions,
      },
    });
  };

  // Save repeating exceptions
  const saveRepeatingExceptions = (exceptionStartTime, exceptionEndTime, repeatingDates) => {
    // Original exception start & end hour
    const momentStartHour = moment(exceptionStartTime).format('HH');
    const momentEndHour = moment(exceptionEndTime).format('HH');

    // Original exception start & end minutes
    const momentStartMinutes = moment(exceptionStartTime).format('mm');
    const momentEndMinutes = moment(exceptionEndTime).format('mm');

    // Format new availability exceptions from the `repeatingDates` array
    // of repeating dates
    const repeatingAvailabilityExceptions = repeatingDates.map(date => {
      const start = moment(date)
        .startOf('day')
        .add(momentStartHour, 'hour')
        .add(momentStartMinutes, 'minutes');

      const end = moment(date)
        .startOf('day')
        .add(momentEndHour, 'hour')
        .add(momentEndMinutes, 'minutes');

      return {
        listingId: listing.id.uuid,
        start: start.format(),
        end: end.format(),
        seats: DEFAULT_AVAILABILITY_SEATS,
      };
    });

    return onAddRepeatingAvailabilityExceptions({
      exceptions: repeatingAvailabilityExceptions,
    });
  };

  // Save exception click handler
  const saveException = (values, form) => {
    const { exceptionStartTime, exceptionEndTime, repeatingDates } = values;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats: DEFAULT_AVAILABILITY_SEATS,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(exceptionResponse => {
        // Denormalise repeating dates and check if the exception
        // should repeat
        const denormalisedRepeatingDates = denormaliseRepeatingDates(repeatingDates);
        const shouldRepeat = denormalisedRepeatingDates.length > 0;

        /** Run if exception should repeat */
        if (shouldRepeat) {
          // Add repeating exceptions
          saveRepeatingExceptions(
            timestampToDate(exceptionStartTime),
            timestampToDate(exceptionEndTime),
            denormalisedRepeatingDates
          ).then(exceptionsResponse => {
            // Save exception & repeating exceptions to publicData
            saveExceptionMetadata(
              currentListing.id,
              exceptionResponse.id.uuid,
              values,
              exceptionsResponse
            );
            // Close availability exception modal
            setIsAvailabilityModalOpen(false);
            // Reset form
            form.reset();
          });
        } else {
          /** Run if exception shouldn't repeat */

          // Save exception to publicData
          saveExceptionMetadata(currentListing.id, exceptionResponse.id.uuid, values);
          // Close availability exception modal
          setIsAvailabilityModalOpen(false);
          // Reset form
          form.reset();
        }
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  // Delete exception click handler
  const deleteException = params => {
    onDeleteAvailabilityException(params).then(() => {
      const exceptionMetadata = publicDataAvailabilityExceptions?.find(
        e => e.id === params.id.uuid
      );

      if (exceptionMetadata) {
        // Update the listing
        const filteredExceptions = publicDataAvailabilityExceptions.filter(
          e => e.id !== params.id.uuid
        );
        return onUpdateListing('availability', {
          id: currentListing.id,
          publicData: {
            exceptions: filteredExceptions,
          },
        });
      }
    });
  };

  // Errors
  const showListingError = errors.showListingsError ? (
    <p className={css.error}>
      <FormattedMessage id="EditExperienceAvailabilityPanel.showListingFailed" />
    </p>
  ) : null;

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditExperienceAvailabilityPanel.title"
      values={{
        listingTitle: (
          <ListingLink className={css.listingLink} listing={listing} isExperience>
            {title}
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditExperienceAvailabilityPanel.createListingTitle" />
  );

  const repeatOptions = findOptionsForSelectFilter('repeat', config.custom.experienceFilters);
  const accessOptions = findOptionsForSelectFilter('access', config.custom.experienceFilters);

  const accessFirstOption = accessOptions[0].key;

  return (
    <main className={classes}>
      <div className={css.form}>
        <div className={css.content}>
          <div className={css.contentWrapper}>
            <h1 className={css.title}>{panelTitle}</h1>
            <p className={css.subTitle}>
              <FormattedMessage id="EditExperienceAvailabilityForm.subTitle" />
            </p>

            {showListingError}

            <div className={css.exceptionLegends}>
              <div className={css.exceptionLegend}>
                <span className={css.exceptionLegendColor} />
                <span className={css.exceptionLegendText}>
                  <FormattedMessage id="EditExperienceAvailabilityPanel.legendFree" />
                </span>
              </div>
              <div className={css.exceptionLegend}>
                <span
                  className={classNames(css.exceptionLegendColor, css.exceptionLegendColorMembers)}
                />
                <span className={css.exceptionLegendText}>
                  <FormattedMessage id="EditExperienceAvailabilityPanel.legendMembers" />
                </span>
              </div>
            </div>
            <AvailabilityExceptions
              timeZone={defaultTimeZone()}
              availabilityExceptions={sortedAvailabilityExceptions}
              publicDataAvailabilityExceptions={publicDataAvailabilityExceptions}
              fetchExceptionsInProgress={fetchExceptionsInProgress}
              deleteExceptionInProgress={deleteExceptionInProgress}
              deleteExceptionId={deleteExceptionId}
              onDeleteAvailabilityException={deleteException}
            />
            <InlineTextButton
              className={css.addExceptionButton}
              onClick={() => setIsAvailabilityModalOpen(true)}
              disabled={exceptionButtonDisabled}
            >
              <FormattedMessage id="EditExperienceAvailabilityPanel.addException" />
            </InlineTextButton>

            <div className={css.submitButtonRoot}>
              <EditWizardButton
                className={css.backButton}
                type="button"
                onClick={onRedirectToPreviousTab}
              >
                <FormattedMessage id="EditExperienceAvailabilityPanel.backButton" />
              </EditWizardButton>
              <div className={css.submitAndPreviewButton}>
                <PreviewListingButton listing={listing} />
                {!isPublished ? (
                  <EditWizardButton
                    type="button"
                    onClick={() => {
                      // As we create timeslots with availability exceptions
                      // API, we don't need to send any data when the tab is
                      // is updated, but we need to call the function so that
                      // the listing can be published.
                      const updateValues = {};
                      onSubmit(updateValues);
                    }}
                  >
                    {submitButtonText}
                  </EditWizardButton>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityForm"
          isOpen={isAvailabilityModalOpen}
          onClose={() => setIsAvailabilityModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditExperienceAvailabilityForm
            formId="EditExperienceAvailabilityForm"
            onSubmit={saveException}
            initialValues={{
              timeZone: defaultTimeZone(),
              access: accessFirstOption,
              repeatingDates: [{}],
            }}
            repeatOptions={repeatOptions}
            accessOptions={accessOptions}
            timeZone={defaultTimeZone()}
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={updateInProgress}
            addExceptionInProgress={addExceptionInProgress || addRepeatingExceptionsInProgress}
            fetchErrors={errors}
            durationConfigOptions={durationConfigOptions}
          />
        </Modal>
      ) : null}
    </main>
  );
};

EditExperienceAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,

  durationConfigOptions: config.custom.durationConfigOptions,
};

EditExperienceAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateMeetingRoom: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,

  durationConfigOptions: array.isRequired,
};

export default EditExperienceAvailabilityPanel;
