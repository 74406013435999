/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import timezones from './forms/EditExperienceAvailabilityForm/timezones';

// ShowPage filters
export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'type',
    label: 'Type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_type'],
    config: {
      options: [
        { key: 'show', label: 'Show' },
        { key: 'experience', label: 'Experience' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    showInTopbar: true,
    config: {
      options: [
        { key: 'circus', label: 'Circus' },
        { key: 'play', label: 'Play' },
        { key: 'dance', label: 'Dance' },
        { key: 'singing', label: 'Singing' },
        { key: 'magic', label: 'Magic' },
        { key: 'comedy', label: 'Comedy' },
        { key: 'dj', label: 'DJ' },
        { key: 'cabaret', label: 'Cabaret' },
        { key: 'opera', label: 'Opera' },
        { key: 'ballet', label: 'Ballet' },
        { key: 'cultural-heritage', label: 'Cultural heritage' },
        { key: 'puppetry', label: 'Puppetry' },
        { key: 'musical', label: 'Musical' },
        { key: 'variety', label: 'Variety' },
      ],
    },
  },
  {
    id: 'genre',
    label: 'Genre',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_genre'],
    config: {
      options: [
        { key: 'theatre-experience', label: 'Theatre experience' },
        { key: 'interactive experience', label: 'Interactive experience' },
        { key: 'touring-tent', label: 'Touring tent' },
        { key: 'cabaret-style', label: 'Cabaret style' },
        { key: 'festival', label: 'Festival' },
        { key: 'outdoor', label: 'Outdoor' },
        { key: 'dducational', label: 'Educational' },
      ],
    },
  },
  {
    id: 'theme',
    label: 'Theme',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_theme'],
    config: {
      options: [
        { key: 'artist', label: 'Artist' },
        { key: 'director', label: 'Director' },
        { key: 'writer', label: 'Writer' },
        { key: 'costume', label: 'Costume' },
        { key: 'music', label: 'Music' },
        { key: 'creative', label: 'Creative' },
        { key: 'art', label: 'Art' },
        { key: 'set-design', label: 'Set design' },
        { key: 'technician', label: 'Technician' },
        { key: 'special-effect', label: 'Special Effect' },
        { key: 'visual-effect', label: 'Visual Effect' },
        { key: 'makeup', label: 'Makeup' },
        { key: 'casting', label: 'Casting' },
      ],
    },
  },
  {
    id: 'status',
    label: 'status',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_status'],
    config: {
      options: [
        {
          key: 'on tour',
          label: 'On tour',
        },
        {
          key: 'in theater',
          label: 'In theater',
        },
        {
          key: 'presales',
          label: 'Presales',
        },
        {
          key: 'in development',
          label: 'In development',
        },
        {
          key: 'looking-for-funding',
          label: 'Looking for funding',
        },
      ],
    },
  },
  {
    id: 'length',
    label: 'Show length',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_length'],
    config: {
      options: [
        {
          key: '30',
          label: '30 min',
        },
        {
          key: '40',
          label: '40 min',
        },
        {
          key: '50',
          label: '50 min',
        },
        {
          key: '60',
          label: '1h',
        },
        {
          key: '70',
          label: '1h 10 min',
        },
        {
          key: '80',
          label: '1h 20 min',
        },
        {
          key: '90-min',
          label: '1h 30 min',
        },
        {
          key: '1h-40-min',
          label: '1h 40 min',
        },
        {
          key: '1h-50-min',
          label: '1h 50 min',
        },
        {
          key: '2-hours',
          label: '2 hours',
        },
        {
          key: '2h-10-min',
          label: '2h 10 min',
        },
        {
          key: '2h-20-min',
          label: '2h 20 min',
        },
        {
          key: '2h-30-min',
          label: '2h 30 min',
        },
        {
          key: '2h-40-min',
          label: '2h 40 min',
        },
        {
          key: '2h-50-min',
          label: '2h 50 min',
        },
        {
          key: '3-hours',
          label: '3 hours',
        },
      ],
    },
  },
  {
    id: 'age',
    label: 'Age',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_age'],
    config: {
      options: [
        {
          key: 'for-everyone',
          label: 'For everyone',
        },
        {
          key: 'age-14-and-up',
          label: 'Age 14 and up',
        },
        {
          key: 'age-15-and-up',
          label: 'Age 15 and up',
        },
        {
          key: 'age-16-and-up',
          label: 'Age 16 and up',
        },
        {
          key: 'age-17-and-up',
          label: 'Age 17 and up',
        },
        {
          key: 'age-18-and-up',
          label: 'Age 18 and up',
        },
      ],
    },
  },
  {
    id: 'language',
    label: 'Language',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_language'],
    config: {
      options: [
        {
          key: 'english',
          label: 'English',
        },
        {
          key: 'mandarin',
          label: 'Mandarin',
        },
        {
          key: 'hindi',
          label: 'Hindi',
        },
        {
          key: 'spanish',
          label: 'Spanish',
        },
        {
          key: 'french',
          label: 'French',
        },
        {
          key: 'arabic',
          label: 'Arabic',
        },
        {
          key: 'bengali',
          label: 'Bengali',
        },
        {
          key: 'russian',
          label: 'Russian',
        },
        {
          key: 'portuguese',
          label: 'Portuguese',
        },
        {
          key: 'indonesian',
          label: 'Indonesian',
        },
        {
          key: 'japanese',
          label: 'Japanese',
        },
      ],
    },
  },
  {
    id: 'date',
    label: 'Date',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_date'],
    showInTopbar: true,
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'today', label: 'Today' },
        { key: 'tomorrow', label: 'Tomorrow' },
        { key: 'this-week', label: 'This week' },
        { key: 'this-weekend', label: 'This weekend' },
        { key: 'later', label: 'Later' },
        { key: 'all', label: 'All' },
      ],
    },
  },
  {
    id: 'explore',
    label: 'Explore',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_explore'],
    showInTopbar: true,
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'from-behind-the-scenes', label: 'From behind the scenes' },
        { key: 'meet-artist-and-creators', label: 'Meet artist and creators' },
        { key: 'be-part-of-a-show', label: 'Be part of a show' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'community-categories',
    label: 'Categories',
    type: 'SelectSingleFilter',
    group: 'primary',
    hideFilter: true,
    queryParamNames: ['pub_category'],
    config: {
      options: [
        { key: 'faq', label: 'FAQ', color: '#2ecc71' },
        { key: 'announcements', label: 'Announcements', color: '#ffaa00' },
        { key: 'introductions', label: 'Introductions', color: '#AF601A' },
        {
          key: 'project-help',
          label: 'Project Help',
          color: '#B03A2E',
        },
        { key: 'off-topic-chatter', label: 'Off Topic Chatter', color: '#B2BABB' },
      ],
    },
  },
];

/**
 * Options that doesn't go inside filter config as they are mainly used
 * inside form fields (e.g. <FieldSelect />) or for displaying purposes.
 */
export const durationConfig = [
  { key: 'months', label: 'Month(s)' },
  { key: 'days', label: 'Day(s)' },
];

export const perksConfig = [
  { key: 'members-only-live-event', label: 'Members-only live event (default)', disabled: true },
  { key: 'promo-code-for-ticket', label: 'Promo code for ticket (delivered by message)' },
  { key: 'discount-for-merch', label: 'Discount for merch (delivered by message)' },
  { key: 'shout-outs', label: 'Shout-outs' },
  { key: 'early ticket access', label: 'Early ticket access (delivered by message)' },
  { key: 'be-part-of-show-credit-when-launched', label: 'Be part of show credit when launched' },
  { key: 'special-event-invitation', label: 'Special event invitation (delivered by message)' },
];

export const donationConfig = [
  {
    id: 'e0ba7ccc-370c-454d-b822-094776957101',
    key: '25-donation',
    label: 'One-time donation',
    price: { amount: 2500, currency: process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY },
  },
  {
    id: 'c74e0718-a748-4ef7-ab8b-68ba6ca76dc3',
    key: '50-donation',
    label: 'One-time donation',
    price: { amount: 5000, currency: process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY },
  },
  {
    id: '0671bca8-6be2-4fa2-9a36-cc693386db5a',
    key: '100-donation',
    label: 'One-time donation',
    price: { amount: 10000, currency: process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY },
  },
  {
    id: '073aca5f-a232-4d97-bf78-825b037d57dd',
    key: '200-donation',
    label: 'One-time donation',
    price: { amount: 20000, currency: process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY },
  },
  {
    id: '5b863d93-73d7-4c15-ab88-58a3cb68dfa7',
    key: '500-donation',
    label: 'One-time donation',
    price: { amount: 50000, currency: process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY },
  },
];

/**
 * ExperiencePage filters
 */
export const experienceFilters = [
  {
    id: 'repeat',
    label: 'Repetition',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_repeat'],
    config: {
      options: [
        { key: 'no', label: 'No repeat' },
        { key: 'yes', label: 'Repeat' },
      ],
    },
  },
  {
    id: 'access',
    label: 'Access',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_access'],
    config: {
      options: [
        { key: 'free', label: 'Free', extraLabel: 'Everyone' },
        { key: 'members', label: 'Members only' },
      ],
    },
  },
];

/**
 * ManageListingPage configs
 */
export const manageFilters = [
  {
    id: 'view',
    label: 'Listing view',
    queryParamName: 'view',
    config: {
      options: [
        { key: 'list', label: 'List' },
        { key: 'grid', label: 'Grid' },
      ],
    },
  },
  {
    id: 'type',
    label: 'Listing type',
    queryParamName: 'pub_type',
    config: {
      options: [
        { key: 'all-listings', label: 'All listings' },
        { key: 'show', label: 'Shows' },
        { key: 'experience', label: 'Experiences' },
      ],
    },
  },
];

export const manageListingTableHeadings = [
  { key: 'listing', label: 'Listing' },
  { key: 'type', label: 'Type' },
  { key: 'status', label: 'Status' },
  { key: 'subscribers', label: 'Subscribers' },
  { key: 'action', label: 'Action' },
];

export const manageSubscriptionTableHeadings = [
  { key: 'listing', label: 'Listing' },
  { key: 'tier', label: 'Tier' },
  { key: 'status', label: 'Status' },
  { key: 'effective', label: 'Effective until' },
  { key: 'action', label: 'Action' },
];

export const analyticsEpisodesTableHeadings = [
  { key: 'title', label: 'Title' },
  { key: 'published', label: 'Published' },
  { key: 'total-views', label: 'Total views' },
];

export const membersSubscribersTableHeadings = [
  { key: 'name', label: 'Name' },
  { key: 'pledge', label: 'Pledge' },
  { key: 'join-date', label: 'Join date' },
  { key: 'status', label: 'Status' },
];

/**
 * StreamPage configs
 */
export const streamFilters = [
  {
    id: 'timezone',
    label: 'Timezone',
    queryParamName: 'timezone',
    config: {
      options: timezones.map(t => ({ key: t, label: t })),
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

/**
 * ScrollableNav is used to present the specific page
 * scrollable navigation.
 */
export const scrollableNav = {
  ExperiencePage: [
    // {
    //   id: 'ExperiencePage.sectionCover',
    //   messageId: 'ExperiencePage.overview',
    //   scrollParams: {
    //     id: 'overview',
    //   },
    // },
    {
      id: 'ExperiencePage.sectionHighlights',
      messageId: 'ExperiencePage.highlights',
      scrollParams: {
        id: 'highlights',
      },
    },
    {
      id: 'ExperiencePage.sectionHost',
      messageId: 'ExperiencePage.host',
      scrollParams: {
        id: 'host',
      },
    },
    {
      id: 'ExperiencePage.sectionComments',
      messageId: 'ExperiencePage.comments',
      scrollParams: {
        id: 'comments',
      },
    },
  ],
};

// List of available durations
export const durationConfigOptions = [
  { value: 0.5, label: '30 minutes' },
  { value: 1, label: '1 hour' },
  { value: 1.5, label: '1 hour 30 minutes' },
  { value: 2, label: '2 hours' },
  { value: 2.5, label: '2 hours 30 minutes' },
  { value: 3, label: '3 hours' },
  { value: 3.5, label: '3 hours 30 minutes' },
  { value: 4, label: '4 hours' },
  { value: 4.5, label: '4 hours 30 minutes' },
  { value: 5, label: '5 hours' },
  { value: 5.5, label: '5 hours 30 minutes' },
];

export const creditRolesConfigOptions = [
  { value: 'executive-director', label: 'Executive director' },
  { value: 'production-director', label: 'Production director' },
  { value: 'technical-director', label: 'Technical director' },
  { value: 'creative-director', label: 'Creative director' },
  { value: 'system-technician', label: 'System technician' },
  { value: 'stage-manager', label: 'Stage manager' },
  { value: 'stagehand', label: 'Stagehand' },
  { value: 'production-coordinator', label: 'Production coordinator' },
  { value: 'artistic-director', label: 'Artistic director' },
  { value: 'artistic-coordinator', label: 'Artistic coordinator' },
  { value: 'tour-manager', label: 'Tour manager' },
  { value: 'tour-coordinator', label: 'Tour coordinator' },
  { value: 'marketing-director', label: 'Marketing director' },
  { value: 'operation-director', label: 'Operation director' },
  { value: 'administrative', label: 'Administrative' },
  { value: 'stage-set', label: 'Stage set' },
  { value: 'prop-designer', label: 'Prop designer' },
  { value: 'lighting-designer', label: 'Lighting designer' },
  { value: 'lighting-technician', label: 'Lighting technician' },
  { value: 'sound-engineer', label: 'Sound engineer' },
  { value: 'sound-designer', label: 'Sound designer' },
  { value: 'video-design', label: 'Video design' },
  { value: 'special-effects', label: 'Special effects' },
  { value: 'rigger', label: 'Rigger' },
  { value: 'choreographer', label: 'Choreographer' },
  { value: 'costume', label: 'Costume' },
  { value: 'makeup', label: 'Makeup' },
  { value: 'writer', label: 'Writer' },
  { value: 'editor', label: 'Editor' },
  { value: 'researcher', label: 'Researcher' },
  { value: 'artist', label: 'Artist' },
  { value: 'dancer', label: 'Dancer' },
  { value: 'acrobat', label: 'Acrobat' },
  { value: 'coach', label: 'Coach' },
  { value: 'casting', label: 'Casting' },
  { value: 'composer', label: 'Composer' },
  { value: 'conductor', label: 'Conductor' },
  { value: 'musician', label: 'Musician' },
  { value: 'orchestra', label: 'Orchestra' },
  { value: 'singer', label: 'Singer' },
];
