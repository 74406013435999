import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { DASHBOARD_PAGES } from '../../routeConfiguration';
import { InlineTextButton, IconClose } from '../../components';
import classNames from 'classnames';

import css from './Topbar.module.css';

const TopbarNotice = props => {
  const { currentPage } = props;

  const [visible, setInvisible] = useState(false);

  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.topbarNoticeAccepted !== '1') {
      setInvisible(true);
    }
  }, []);

  const saveCloseConsent = () => {
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 14);
    // Save the cookie with expiration date
    document.cookie = 'topbarNoticeAccepted=1; path=/; expires=' + expirationDate.toGMTString();
  };

  const onCloseNotice = () => {
    saveCloseConsent();
    setInvisible(false);
  };

  const isDashboardPage = DASHBOARD_PAGES.includes(currentPage);
  const classes = classNames(css.notice, {
    [css.noticeDashboard]: isDashboardPage,
  });
  const contentClassName = isDashboardPage ? css.noticeContentDashboard : css.noticeContent;

  return visible ? (
    <div className={classes}>
      <div className={contentClassName}>
        <span className={css.noticeText}>
          <FormattedMessage id="Topbar.notice" />
        </span>
        <InlineTextButton type="button" className={css.closeNoticeButton} onClick={onCloseNotice}>
          <IconClose className={css.closeNoticeIcon} />
        </InlineTextButton>
      </div>
    </div>
  ) : null;
};

TopbarNotice.defaultProps = {
  currentPage: null,
};

TopbarNotice.propTypes = {
  currentPage: string,
};

export default TopbarNotice;
