import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { VideoPlayer, NamedLink, SecondaryButton } from '../../components';
import classNames from 'classnames';
import css from './SectionListingVideos.module.css';

const MAX_MOBILE_LAYOUT_WIDTH = 768;
const MAX_TABLET_LAYOUT_WIDTH = 1024;

const ITEMS_PER_PAGE_MOBILE = 2;
const ITEMS_PER_PAGE_TABLET = 3;
const ITEMS_PER_PAGE_DESKTOP = 5;

// VideoPlayer settings
const VIDEO_PLAYER_SETTINGS = { bigPlayButton: true };

const SectionListingVideos = props => {
  const {
    rootClassName,
    className,
    viewport,
    videos,
    queryListingVideosInProgress,
    queryListingVideosError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const itemsPerPage =
    viewport.width < MAX_MOBILE_LAYOUT_WIDTH
      ? ITEMS_PER_PAGE_MOBILE
      : viewport.width <= MAX_TABLET_LAYOUT_WIDTH
      ? ITEMS_PER_PAGE_TABLET
      : ITEMS_PER_PAGE_DESKTOP;

  const [visibleCount, setVisibleCount] = useState(itemsPerPage);

  useEffect(() => {
    setVisibleCount(currentVisible => {
      if (currentVisible !== itemsPerPage) {
        return itemsPerPage;
      }
      return currentVisible;
    });
  }, [itemsPerPage]);

  const videosWithViews = useMemo(() => {
    return videos.map(video => ({
      ...video,
      views: Math.floor(Math.random() * 100) + 1,
    }));
  }, [videos]);

  const loadMore = () => setVisibleCount(prevCount => prevCount + itemsPerPage);

  if (queryListingVideosInProgress || queryListingVideosError || videos.length === 0) {
    return null;
  }

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionListingVideos.title" />
      </h2>
      <div className={css.videos}>
        {videosWithViews.slice(0, visibleCount).map(video => {
          const authorLink = (
            <NamedLink
              className={css.authorLink}
              name="ArtistProfilePage"
              params={{ id: video.author.id }}
            >
              {video.author.displayName}
            </NamedLink>
          );

          return (
            <div key={video.id} className={css.videoPlayerWrapper}>
              <VideoPlayer video={video} settings={VIDEO_PLAYER_SETTINGS} />
              <span className={css.author}>
                <FormattedMessage id="SectionListingVideos.author" values={{ authorLink }} />
              </span>
              <span className={css.views}>
                <FormattedMessage id="SectionListingVideos.views" values={{ views: video.views }} />
              </span>
            </div>
          );
        })}
      </div>

      {visibleCount < videos.length && (
        <div className={css.loadMoreButtonContainer}>
          <SecondaryButton onClick={loadMore} className={css.loadMoreButton}>
            <FormattedMessage id="SectionListingVideos.loadMore" />
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};

SectionListingVideos.defaultProps = {
  rootClassName: null,
  className: null,
  queryListingVideosInProgress: false,
  queryListingVideosError: null,
};

const { arrayOf, bool, string } = PropTypes;

SectionListingVideos.propTypes = {
  rootClassName: string,
  className: string,
  videos: arrayOf(propTypes.video).isRequired,
  queryListingVideosInProgress: bool.isRequired,
  queryListingVideosError: propTypes.error,
};

export default SectionListingVideos;
